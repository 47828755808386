/// <reference path="./custom.d.ts" />
// tslint:disable
/**
 * GetEnergy API Service
 * Документация по API.
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import * as url from 'url';
import * as portableFetch from 'portable-fetch';
import { Configuration } from './configuration';

const BASE_PATH = 'process.env.REACT_APP_API_URL'.replace(/\/+$/, '');

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
  csv: ',',
  ssv: ' ',
  tsv: '\t',
  pipes: '|',
};

/**
 *
 * @export
 * @interface FetchAPI
 */
export interface FetchAPI {
  (url: string, init?: any): Promise<Response>;
}

/**
 *
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
  url: string;
  options: any;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
  protected configuration?: Configuration;

  constructor(
    configuration?: Configuration,
    protected basePath: string = BASE_PATH,
    protected fetch: FetchAPI = portableFetch,
  ) {
    if (configuration) {
      this.configuration = configuration;
      this.basePath = configuration.basePath || this.basePath;
    }
  }
}

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
  // @ts-ignore
  name: 'RequiredError';
  constructor(public field: string, msg?: string) {
    super(msg);
  }
}

/**
 *
 * @export
 * @interface SeError
 */
export interface SeError {}

/**
 *
 * @export
 * @interface SeIAMEmployeesChangePassword
 */
export interface SeIAMEmployeesChangePassword {
  /**
   *
   * @type {string}
   * @memberof SeIAMEmployeesChangePassword
   */
  oldPassword: string;
  /**
   *
   * @type {string}
   * @memberof SeIAMEmployeesChangePassword
   */
  newPassword: string;
}

/**
 *
 * @export
 * @interface SeIAMEmployeesLogin
 */
export interface SeIAMEmployeesLogin {
  /**
   *
   * @type {string}
   * @memberof SeIAMEmployeesLogin
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof SeIAMEmployeesLogin
   */
  password: string;
}

/**
 *
 * @export
 * @interface SeIAMEmployeesRegistration
 */
export interface SeIAMEmployeesRegistration {
  /**
   *
   * @type {string}
   * @memberof SeIAMEmployeesRegistration
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof SeIAMEmployeesRegistration
   */
  password: string;
}

/**
 *
 * @export
 * @interface SeIAMUsersChangePassword
 */
export interface SeIAMUsersChangePassword {
  /**
   *
   * @type {string}
   * @memberof SeIAMUsersChangePassword
   */
  oldPassword: string;
  /**
   *
   * @type {string}
   * @memberof SeIAMUsersChangePassword
   */
  newPassword: string;
}

/**
 *
 * @export
 * @interface SeIAMUsersLogin
 */
export interface SeIAMUsersLogin {
  /**
   *
   * @type {number}
   * @memberof SeIAMUsersLogin
   */
  phone: number;
  /**
   *
   * @type {number}
   * @memberof SeIAMUsersLogin
   */
  code: number;
}

/**
 *
 * @export
 * @interface SeIAMUsersSendCode
 */
export interface SeIAMUsersSendCode {
  /**
   *
   * @type {number}
   * @memberof SeIAMUsersSendCode
   */
  phone: number;
  /**
   *
   * @type {string}
   * @memberof SeIAMUsersSendCode
   */
  token: string;
}

/**
 *
 * @export
 * @interface SeMessage
 */
export interface SeMessage {
  /**
   *
   * @type {string}
   * @memberof SeMessage
   */
  message?: string;
}

/**
 * IAMApi - fetch parameter creator
 * @export
 */
export const IAMApiFetchParamCreator = function(configuration?: Configuration) {
  return {
    /**
     * Смена пароля сотрудником
     * @param {SeIAMEmployeesChangePassword} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    iamEmployeesChangePasswordPost(body?: SeIAMEmployeesChangePassword, options: any = {}): FetchArgs {
      const localVarPath = `/iam/employees/change-password`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey;
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>'SeIAMEmployeesChangePassword' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || '';

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Получение сотрудником токена через почту и пароль
     * @param {SeIAMEmployeesLogin} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    iamEmployeesLoginPost(body?: SeIAMEmployeesLogin, options: any = {}): FetchArgs {
      const localVarPath = `/iam/employees/login`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>'SeIAMEmployeesLogin' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || '';

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Регистрация сотрудника по почте и паролю
     * @param {SeIAMEmployeesRegistration} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    iamEmployeesRegistrationPost(body?: SeIAMEmployeesRegistration, options: any = {}): FetchArgs {
      const localVarPath = `/iam/employees/registration`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>'SeIAMEmployeesRegistration' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || '';

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Удаление пользователя
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    iamUsersDeleteDelete(options: any = {}): FetchArgs {
      const localVarPath = `/iam/users/delete`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey;
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Отправка цифрового кода для получения токена
     * @param {SeIAMUsersLogin} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    iamUsersLoginPost(body: SeIAMUsersLogin, options: any = {}): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          'body',
          'Required parameter body was null or undefined when calling iamUsersLoginPost.',
        );
      }
      const localVarPath = `/iam/users/login-call`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>'SeIAMUsersLogin' !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || '';

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Отправка SMS с цифровым кодом на указанный номер телефона
     * @param {string} region
     * @param {SeIAMUsersSendCode} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    iamUsersSendCodePost(region: string, body: SeIAMUsersSendCode, options: any = {}): FetchArgs {
      // verify required parameter 'region' is not null or undefined
      if (region === null || region === undefined) {
        throw new RequiredError(
          'region',
          'Required parameter region was null or undefined when calling iamUsersSendCodePost.',
        );
      }
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          'body',
          'Required parameter body was null or undefined when calling iamUsersSendCodePost.',
        );
      }
      const localVarPath = `/iam/users/send-code-call`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (region !== undefined && region !== null) {
        localVarHeaderParameter['ge-region'] = String(region);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>'SeIAMUsersSendCode' !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || '';

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * IAMApi - functional programming interface
 * @export
 */
export const IAMApiFp = function(configuration?: Configuration) {
  return {
    /**
     * Смена пароля сотрудником
     * @param {SeIAMEmployeesChangePassword} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    iamEmployeesChangePasswordPost(
      body?: SeIAMEmployeesChangePassword,
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<SeMessage> {
      const localVarFetchArgs = IAMApiFetchParamCreator(configuration).iamEmployeesChangePasswordPost(body, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Получение сотрудником токена через почту и пароль
     * @param {SeIAMEmployeesLogin} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    iamEmployeesLoginPost(
      body?: SeIAMEmployeesLogin,
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<SeMessage> {
      const localVarFetchArgs = IAMApiFetchParamCreator(configuration).iamEmployeesLoginPost(body, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Регистрация сотрудника по почте и паролю
     * @param {SeIAMEmployeesRegistration} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    iamEmployeesRegistrationPost(
      body?: SeIAMEmployeesRegistration,
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<SeMessage> {
      const localVarFetchArgs = IAMApiFetchParamCreator(configuration).iamEmployeesRegistrationPost(body, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Удаление пользователя
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    iamUsersDeleteDelete(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SeMessage> {
      const localVarFetchArgs = IAMApiFetchParamCreator(configuration).iamUsersDeleteDelete(options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Отправка цифрового кода для получения токена
     * @param {SeIAMUsersLogin} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    iamUsersLoginPost(
      body: SeIAMUsersLogin,
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<SeMessage> {
      const localVarFetchArgs = IAMApiFetchParamCreator(configuration).iamUsersLoginPost(body, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Отправка SMS с цифровым кодом на указанный номер телефона
     * @param {string} region
     * @param {SeIAMUsersSendCode} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    iamUsersSendCodePost(
      region: string,
      body: SeIAMUsersSendCode,
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<SeMessage> {
      const localVarFetchArgs = IAMApiFetchParamCreator(configuration).iamUsersSendCodePost(region, body, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
  };
};

/**
 * IAMApi - factory interface
 * @export
 */
export const IAMApiFactory = function(configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
  return {
    /**
     * Смена пароля сотрудником
     * @param {SeIAMEmployeesChangePassword} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    iamEmployeesChangePasswordPost(body?: SeIAMEmployeesChangePassword, options?: any) {
      return IAMApiFp(configuration).iamEmployeesChangePasswordPost(body, options)(fetch, basePath);
    },
    /**
     * Получение сотрудником токена через почту и пароль
     * @param {SeIAMEmployeesLogin} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    iamEmployeesLoginPost(body?: SeIAMEmployeesLogin, options?: any) {
      return IAMApiFp(configuration).iamEmployeesLoginPost(body, options)(fetch, basePath);
    },
    /**
     * Регистрация сотрудника по почте и паролю
     * @param {SeIAMEmployeesRegistration} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    iamEmployeesRegistrationPost(body?: SeIAMEmployeesRegistration, options?: any) {
      return IAMApiFp(configuration).iamEmployeesRegistrationPost(body, options)(fetch, basePath);
    },
    /**
     * Удаление пользователя
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    iamUsersDeleteDelete(options?: any) {
      return IAMApiFp(configuration).iamUsersDeleteDelete(options)(fetch, basePath);
    },
    /**
     * Отправка цифрового кода для получения токена
     * @param {SeIAMUsersLogin} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    iamUsersLoginPost(body: SeIAMUsersLogin, options?: any) {
      return IAMApiFp(configuration).iamUsersLoginPost(body, options)(fetch, basePath);
    },
    /**
     * Отправка SMS с цифровым кодом на указанный номер телефона
     * @param {string} region
     * @param {SeIAMUsersSendCode} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    iamUsersSendCodePost(region: string, body: SeIAMUsersSendCode, options?: any) {
      return IAMApiFp(configuration).iamUsersSendCodePost(region, body, options)(fetch, basePath);
    },
  };
};

/**
 * IAMApi - object-oriented interface
 * @export
 * @class IAMApi
 * @extends {BaseAPI}
 */
export class IAMApi extends BaseAPI {
  /**
   * Смена пароля сотрудником
   * @param {SeIAMEmployeesChangePassword} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof IAMApi
   */
  public iamEmployeesChangePasswordPost(body?: SeIAMEmployeesChangePassword, options?: any) {
    return IAMApiFp(this.configuration).iamEmployeesChangePasswordPost(body, options)(this.fetch, this.basePath);
  }

  /**
   * Получение сотрудником токена через почту и пароль
   * @param {SeIAMEmployeesLogin} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof IAMApi
   */
  public iamEmployeesLoginPost(body?: SeIAMEmployeesLogin, options?: any) {
    return IAMApiFp(this.configuration).iamEmployeesLoginPost(body, options)(this.fetch, this.basePath);
  }

  /**
   * Регистрация сотрудника по почте и паролю
   * @param {SeIAMEmployeesRegistration} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof IAMApi
   */
  public iamEmployeesRegistrationPost(body?: SeIAMEmployeesRegistration, options?: any) {
    return IAMApiFp(this.configuration).iamEmployeesRegistrationPost(body, options)(this.fetch, this.basePath);
  }

  /**
   * Удаление пользователя
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof IAMApi
   */
  public iamUsersDeleteDelete(options?: any) {
    return IAMApiFp(this.configuration).iamUsersDeleteDelete(options)(this.fetch, this.basePath);
  }

  /**
   * Отправка цифрового кода для получения токена
   * @param {SeIAMUsersLogin} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof IAMApi
   */
  public iamUsersLoginPost(body: SeIAMUsersLogin, options?: any) {
    return IAMApiFp(this.configuration).iamUsersLoginPost(body, options)(this.fetch, this.basePath);
  }

  /**
   * Отправка SMS с цифровым кодом на указанный номер телефона
   * @param {string} region
   * @param {SeIAMUsersSendCode} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof IAMApi
   */
  public iamUsersSendCodePost(region: string, body: SeIAMUsersSendCode, options?: any) {
    return IAMApiFp(this.configuration).iamUsersSendCodePost(region, body, options)(this.fetch, this.basePath);
  }
}

/**
 * UsersApi - fetch parameter creator
 * @export
 */
export const UsersApiFetchParamCreator = function(configuration?: Configuration) {
  return {
    /**
     * Получить список всех пользователей
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersAllGet(options: any = {}): FetchArgs {
      const localVarPath = `/users/all`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey;
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Получить пользователя по id
     * @param {string} id Id-пользователя
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersByIdGet(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling usersByIdGet.');
      }
      const localVarPath = `/users/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey;
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
  return {
    /**
     * Получить список всех пользователей
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersAllGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<any>> {
      const localVarFetchArgs = UsersApiFetchParamCreator(configuration).usersAllGet(options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Получить пользователя по id
     * @param {string} id Id-пользователя
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersByIdGet(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
      const localVarFetchArgs = UsersApiFetchParamCreator(configuration).usersByIdGet(id, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
  };
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function(configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
  return {
    /**
     * Получить список всех пользователей
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersAllGet(options?: any) {
      return UsersApiFp(configuration).usersAllGet(options)(fetch, basePath);
    },
    /**
     * Получить пользователя по id
     * @param {string} id Id-пользователя
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersByIdGet(id: string, options?: any) {
      return UsersApiFp(configuration).usersByIdGet(id, options)(fetch, basePath);
    },
  };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
  /**
   * Получить список всех пользователей
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public usersAllGet(options?: any) {
    return UsersApiFp(this.configuration).usersAllGet(options)(this.fetch, this.basePath);
  }

  /**
   * Получить пользователя по id
   * @param {string} id Id-пользователя
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public usersByIdGet(id: string, options?: any) {
    return UsersApiFp(this.configuration).usersByIdGet(id, options)(this.fetch, this.basePath);
  }
}
