import React from 'react';
import { Router } from 'react-router-dom';

import { ErrorBoundary } from './components';
import { Provider } from 'mobx-react';
import { General } from './pages/General';
import { store } from './store';
import { history } from './store/router';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

interface Props {}

const captchaSitekey = '6LdsKbcpAAAAACSr2qf4MXFQFWI9flUjAL1FiXEY';

export const App: React.FC<Props> = () => {
  return (
    <ErrorBoundary>
      <Provider store={store}>
        <GoogleReCaptchaProvider reCaptchaKey={captchaSitekey} useEnterprise={true}>
          <Router history={history}>
            <General />
          </Router>
        </GoogleReCaptchaProvider>
      </Provider>
    </ErrorBoundary>
  );
};
