import React, { Component } from 'react';
import { UiHeadline, IconCalloutAutomat } from '../../ui';
import { GetChargingForm } from './GetChargingForm';

interface Props {
  onSubmit: (code: number) => void;
  resetRequestErrors?: () => void;
  error?: string;
  isPaymentError?: boolean;
  onUpdatePaymentMethod: () => void;
}

export class GetChargingCheck extends Component<Props> {
  render() {
    const { error, isPaymentError, onSubmit, onUpdatePaymentMethod, resetRequestErrors } = this.props;

    return (
      <>
        <UiHeadline>Введите номер автомата, рядом с которым вы находитесь</UiHeadline>
        <GetChargingForm
          onSubmit={onSubmit}
          error={error}
          resetRequestErrors={resetRequestErrors}
          isPaymentError={isPaymentError}
          onUpdatePaymentMethod={onUpdatePaymentMethod}
        />
        <div className="bAutomatCodeHint mt-36">
          <div className="bAutomatCodeHint__imgWrapper">
            <img className="bAutomatCodeHint__img" src="assets/img/automat.png" />
          </div>
        </div>
      </>
    );
  }
}
