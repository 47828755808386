import React from 'react';
import Modal from 'react-modal';

type Props = typeof defaultProps & {
  show: boolean;
  children: React.ReactNode;
  onCloseModal?: () => void;
  parentSelector: () => HTMLElement;
};

const defaultProps = {
  show: false,
  parentSelector: () => document.querySelector('#root'),
};

Modal.setAppElement('#root');

export class Popup extends React.Component<Props> {
  static readonly defaultProps = defaultProps;

  render() {
    const { show, parentSelector, onCloseModal } = this.props;
    return (
      <Modal
        isOpen={show}
        portalClassName="bPopup"
        overlayClassName="bPopup__overlay"
        className="container_sm"
        onRequestClose={onCloseModal}
        parentSelector={parentSelector}
      >
        <div className="panel">{this.props.children}</div>
      </Modal>
    );
  }
}
