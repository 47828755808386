import React, { Component, createRef, RefObject } from 'react';
import { find, findIndex } from 'lodash';
import { documentListenerMulti } from '../../utils';
import { InfoCardItem } from './InfoCardItem';

interface Props {
  children: React.ReactNodeArray;
  closeHelpers?: Function | null;
}

export class InfoCard extends Component<Props> {
  public static Item = InfoCardItem;

  private infoCardRef: RefObject<HTMLElement>[] = React.Children.map(this.props.children, () =>
    createRef<HTMLElement>(),
  );

  state = {
    active: -1,
    reset: false,
  };

  componentDidMount() {
    this.setEventListener(true);
  }

  componentWillUnmount() {
    const { closeHelpers } = this.props;
    closeHelpers && closeHelpers();
    this.setEventListener();
  }

  setEventListener = (add?: boolean) => {
    add
      ? documentListenerMulti('click touchstart', this.handlerClickOutside)
      : documentListenerMulti('click touchstart', this.handlerClickOutside, 'remove');
  };

  handlerClickOutside = (e: Event) => {
    const { children, closeHelpers } = this.props;
    const target = e.target as HTMLElement;
    const helperIndex = findIndex(children, { props: { typeCard: 'helper', show: true } });

    if (this.state.active < 0 && helperIndex > -1) {
      this.setState({ active: helperIndex });
    }

    if (this.infoCardRef[this.state.active]) {
      const ref = this.infoCardRef[this.state.active].current as HTMLElement;
      if (!ref.contains(target) || this.state.reset) {
        if (this.state.active === helperIndex) {
          closeHelpers && closeHelpers();
        } else {
          this.setState({ active: -1, reset: false });
        }
      } else {
        if (this.state.active === helperIndex && target.classList.contains('btn')) {
          closeHelpers && closeHelpers();
          this.setState({ active: -1, reset: false });
        }
      }
    }
  };

  handlerClickCard = (index: number) => {
    const { children, closeHelpers } = this.props;
    const helperIndex = findIndex(children, { props: { typeCard: 'helper', show: true } });
    if (index !== helperIndex && helperIndex > -1) {
      closeHelpers && closeHelpers();
    }
    this.setState({ active: index });
  };

  handlerCloseAll = () => {
    this.setState({ reset: true });
  };

  renderChild = (child: any, index: number) =>
    child &&
    React.createElement(child.type, {
      ...child.props,
      index,
      closedAlways: child.props.typeCard === 'helper',
      key: `infoCardContent-${index}`,
      onClick: () => this.handlerClickCard(index),
      closeAll: this.handlerCloseAll,
      minimized: child.props.typeCard !== 'helper' ? this.state.active !== index : false,
      ref: this.infoCardRef[index],
    });

  render() {
    return React.Children.map(this.props.children, this.renderChild);
  }
}
