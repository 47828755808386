import React from 'react';
import { UiHeader, UiHeaderBack } from '../../ui';
import { INavigation } from '../../store/modules';

interface Props {
  nav?: INavigation;
}

export class NavHeader extends React.Component<Props> {
  render() {
    return (
      <UiHeader>
        <UiHeaderBack nav={this.props.nav} />
      </UiHeader>
    );
  }
}
