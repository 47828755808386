import React from 'react';

import { Page } from '../../components';

interface Props {}

export class Contacts extends React.Component<Props> {
  render() {
    return (
      <Page title="Контакты">
        <p className="textBody-2">
          <span className="textBold">Поддержка: </span>
          <a href="tel:+7 (495) 642-27-56">+7 (495) 642-27-56</a>, <a href="tel:8 (800) 550-16-32">8 (800) 550-16-32</a>
        </p>
        <p className="textBody-2">
          <span className="textBold">Адрес:</span> 119334, город Москва, проезд Донской 5-й, дом 21Б, строение 10, этаж 2, помещение I, комната 2
        </p>
      </Page>
    );
  }
}
