import { Configuration, IAMApi } from './generated-auth';
import { basePath } from './config';
import { AppStorage } from './appStorage';
import { Api } from './api';

const TOKEN_KEY = 'token';

export class Auth {
  private instance = new IAMApi(new Configuration({ basePath }));

  constructor(private appStorage: AppStorage, private api: Api) {}

  async logOut() {
    const apiKey = `Bearer ${this.appStorage.get(TOKEN_KEY)}`;
    const instance = new IAMApi(new Configuration({ apiKey, basePath }));
    try {
      const response = await instance.iamUsersDeleteDelete();
      this.appStorage.remove(TOKEN_KEY);
      console.info(response.message);
    } catch (e) {
      console.error(e);
    }
  }

  get client() {
    return this.instance;
  }

  get privateClient() {
    const apiKey = `Bearer ${this.appStorage.get(TOKEN_KEY)}`;
    const instance = new IAMApi(new Configuration({ apiKey, basePath }));
    return instance;
  }

  get getToken() {
    return this.appStorage.get(TOKEN_KEY);
  }

  public setToken(token: string) {
    this.appStorage.save(TOKEN_KEY, token);
  }

  public removeToken() {
    this.appStorage.remove(TOKEN_KEY);
  }
}
