import React, { Component } from 'react';
import { UiContainer, UiHeadline } from '../../ui';

interface Props {
  title?: React.ReactNode;
  children?: React.ReactNode;
}

export class Page extends Component<Props> {
  render() {
    const { title, children } = this.props;
    return (
      <UiContainer>
        <UiHeadline>{title}</UiHeadline>
        {children}
      </UiContainer>
    );
  }
}
