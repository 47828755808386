import React, { useState } from 'react';
import cn from 'classnames';
import { Formik, Form as Forma, FormikValues, FormikActions, FormikErrors } from 'formik';
import { toUpFirst } from '../../utils';
import { UiButton } from '../../ui';
import { DisplayFormikState } from './FormStateHelper';

export interface SubmitProps<Values> {
  values: Values;
  formikActions: FormikActions<Values>;
  callback?: any;
}

interface Props<Values> {
  enableReinitialize?: boolean;
  statusTitle?: string;
  onSubmit: (props: SubmitProps<Values>) => void;
  align?: string;
  className?: string;
  children: React.ReactNode;
  initialValues: Values;
  validationSchema?: any | (() => any);
  validate?: (values: Values) => void | object | Promise<FormikErrors<Values>>;
  disabledIsEqual?: boolean;
  btnClassName?: string;
  btnTitle?: string;
  footer?: React.ReactNode;
  loading?: boolean;
  showFormState?: boolean;
  resetRequestErrors?: () => void;
  id?: string;
  autoComplete?: string;
}

export const Form = <Values extends FormikValues>({
  enableReinitialize,
  statusTitle,
  onSubmit,
  initialValues,
  validate,
  validationSchema,
  children,
  className,
  disabledIsEqual,
  btnClassName,
  footer,
  loading,
  showFormState,
  resetRequestErrors,
  id,
  autoComplete,
  btnTitle = 'Продолжить',
  align = 'center',
}: Props<Values>) => {
  const [status, setStatus] = useState('');

  const callbackAction = () => {
    statusTitle && setStatus(statusTitle);
  };

  const handleOnSubmit = (values: Values, formikActions: FormikActions<Values>) => {
    onSubmit({ values, formikActions, callback: callbackAction });
  };

  const handleFormChange = () => {
    statusTitle && setStatus('');
    resetRequestErrors && resetRequestErrors();
  };

  const isInitialValid = (props: any) => {
    if (!props.validationSchema) return true;
    return props.validationSchema.isValidSync(props.initialValues);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleOnSubmit}
      validationSchema={validationSchema}
      validate={validate}
      isInitialValid={isInitialValid}
      enableReinitialize={enableReinitialize}
      render={({ isValid, dirty, ...props }) => {
        const disabled = disabledIsEqual ? !!status || !dirty || !isValid : !isValid;
        return (
          <Forma
            className={cn(className, `text${toUpFirst(align)}`)}
            id={id}
            autoComplete={autoComplete}
            onChange={handleFormChange}
          >
            {children}
            <UiButton type="submit" className={cn('btn_widthFull', btnClassName)} disabled={loading || disabled}>
              {status || btnTitle}
            </UiButton>
            {footer}
            {showFormState && <DisplayFormikState {...{ isValid, dirty, ...props }} />}
          </Forma>
        );
      }}
    />
  );
};
