import React from 'react';
import { periodTimer, timeToUse } from '../../utils';

type Props = typeof defaultProps & {
  time?: Date;
  value?: number;
  fn?: (date?: Date) => string;
  tickInterval?: 'hour' | 'minute';
};

interface State {
  timer?: string;
}

const defaultProps = {
  interval: 1000,
};

export class InfoCardTimer extends React.Component<Props, State> {
  static readonly defaultProps = defaultProps;
  state = {
    timer: this.props.fn
      ? this.props.fn(this.props.time)
      : this.props.value
      ? timeToUse(this.props.value)
      : periodTimer(this.props.time),
  };

  timerID: any = null;

  componentDidMount() {
    this.timerID = setInterval(() => this.tick(), this.currentInterval(this.props.tickInterval));
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  currentInterval = (tick?: string) => {
    const { interval } = this.props;
    switch (tick) {
      case 'hour':
        return interval * 60 * 60;
      case 'minute':
        return interval * 60;
      default:
        return interval;
    }
  };

  tick() {
    this.setState({
      timer: this.props.fn
        ? this.props.fn(this.props.time)
        : this.props.value
        ? timeToUse(this.props.value)
        : periodTimer(this.props.time),
    });
  }

  render() {
    return <span>{this.state.timer}</span>;
  }
}
