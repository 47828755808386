import React from 'react';
import cn from 'classnames';

type Props = {
  children: React.ReactNode;
  className?: string;
};

export const UiContainer = ({ children, className }: Props) => (
  <div className={cn('container_sm', className)}>{children}</div>
);
