import React from 'react';
import cn from 'classnames';

import { IconClose, IconMenuRight } from './Icons';

type Props = {
  isOpen: boolean;
  onClick?: () => void;
};

export const UiMenuButton = ({ isOpen, onClick }: Props) => {
  const Icon = isOpen ? IconClose : IconMenuRight;
  return (
    <button
      className={cn('btn btn_bgTransparent btn_onlyIcon', { bHeader__menuBtn: !isOpen, bMainMenu__closeBtn: isOpen })}
      aria-label={isOpen ? 'Закрыть главное меню' : 'Открыть главное меню'}
      aria-expanded={isOpen}
      aria-controls="mainMenu"
      onClick={onClick}
    >
      <Icon className="icon btn__icon" />
    </button>
  );
};
