import React from 'react';

export interface ListItemProps {
  title: string;
  icon?: any;
}

type Props = {
  list: ListItemProps[];
  type?: string;
};

export const UiInfoCardList = ({ list, type }: Props) =>
  type === 'ordered' ? (
    <ol className="bOrderedList mb-16">
      {list.map((item, index) => (
        <li className="bOrderedList__item" key={`cardList-${index}`}>
          <span className="bOrderedList__number" />
          <span className="textBody-2">{item.title}</span>
        </li>
      ))}
    </ol>
  ) : (
    <ul className="bIconizedList bIconizedList_bulletBgGreen bIconizedList_divided mb-16 textWhite">
      {list.map((item, index) => (
        <li className="bIconizedList__item" key={`cardList-${index}`}>
          <span className="bIconizedList__bullet">{item.icon}</span>
          <span className="textBody-2">{item.title}</span>
        </li>
      ))}
    </ul>
  );
