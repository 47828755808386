import React from 'react';

import { Form, Input, SubmitProps } from '../../components';
import { UiContainer, UiHeadline, UiSpinner } from '../../ui';
import { inject, observer } from 'mobx-react';
import { IStore, IProfileDataSnap } from '../../store/modules';
import { getSnapshot } from 'mobx-state-tree';

interface Props {
  store?: IStore;
}

@inject('store')
@observer
export class Profile extends React.Component<Props> {
  handleSubmit = ({ values, callback }: SubmitProps<IProfileDataSnap>) => {
    const { profile } = this.props.store!;
    profile.editProfile({ name: '', email: '', dtype: 0, ...values }).then(() => callback());
  };

  render() {
    const { profile, chargings } = this.props.store!;

    return (
      <UiContainer>
        <UiHeadline>Профиль</UiHeadline>
        {profile.loading ? (
          <UiSpinner />
        ) : (
          <Form
            align="left"
            initialValues={getSnapshot(profile.data)}
            onSubmit={this.handleSubmit}
            btnTitle="Сохранить изменения"
            statusTitle="Сохранено"
            enableReinitialize
            disabledIsEqual
          >
            <Input name="phone" label="Номер телефона" type="phone" readonly disabled />
            <Input name="name" label="Имя" />
            <Input name="email" label="Email" />
            {/* <Field name="dtype" label="Тип кабеля зарядки" component={Select} list={chargings} /> */}
          </Form>
        )}
      </UiContainer>
    );
  }
}
