import { Api } from './api';
import { Auth } from './auth';
import { AppStorage } from './appStorage';
import { IStore, Store } from './modules';
import Router from './router';
import { getStorageChargingType } from './helpers';

export const chargingList = [
  {
    value: '0',
    label: 'Lightning (iPhone)',
  },
  {
    value: '1',
    label: 'Micro-USB',
  },
  {
    value: '2',
    label: 'Type C',
  },
];

export const appStorage = new AppStorage();
export const api = new Api(appStorage.get('token'));
export const auth = new Auth(appStorage, api);

export const store = Store.create({
  user: {
    token: auth.getToken,
    chargingType: getStorageChargingType(),
  },
  profile: {},
  map: {
    location: JSON.parse(appStorage.get('location') || 'null') || undefined,
  },
  session: {},
  navigation: {
    data: [
      {
        id: 'default',
      },
      {
        id: 'session',
        title: 'Карта автоматов',
      },
      {
        id: 'auth',
      },
    ],
    selected: 'default',
  },
  chargings: chargingList,
  router: Router,
});

export interface IStoreMain {
  store: IStore;
}
