import React from 'react';
import { Field, FieldProps } from 'formik';

interface InputProps {
  required?: boolean;
  disabled?: boolean;
  readonly?: boolean;
}

interface Props extends InputProps {
  name: string;
  label: React.ReactNode;
}

export const Checkbox = ({ name, label, ...props }: Props) => (
  <Field name={name}>
    {({ field }: FieldProps) => (
      <label className="checkbox mb-16">
        <input {...field} {...props} className="visuallyHidden checkbox__input" type="checkbox" />
        <span className="checkbox__btn" />
        <span className="checkbox__label">{label}</span>
      </label>
    )}
  </Field>
);
