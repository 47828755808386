import React from 'react';

import { Route, Switch } from 'react-router-dom';
import { withRouter, Redirect } from 'react-router';
import { observer, inject } from 'mobx-react';

import { UiLayout } from '../../ui';
import { Header, NavHeader, ChargingTypePopup } from '../../components';
import { PrivateRoute } from '../../components/PrivateRoute';
import { SignIn, Main, Profile, GetCharging, PaymentMethod, PaymentMethodForm, Contacts } from '..';

const General = observer(props => {
  const { location } = props;
  const { user, session, navigation } = props.store;
  const mainHeader = location.pathname === '/';
  const isAuth = user.isAuth;
  // const showChargingPopup = !(user.token || user.chargingType);

  return (
    <>
      {mainHeader ? <Header user={user} sessionActive={session.sessionActive} /> : <NavHeader nav={navigation} />}
      <UiLayout>
        <Switch>
          <Route exact path="/" component={Main} />
          <Route path="/contacts" component={Contacts} />
          <PrivateRoute condition={!isAuth} path="/sign-in" component={SignIn} />
          <PrivateRoute exact condition={isAuth} path="/payment-method" component={PaymentMethod} />
          <PrivateRoute condition={isAuth} path="/payment-method/edit" component={PaymentMethodForm} />
          <PrivateRoute condition={isAuth} path="/profile" component={Profile} />
          <PrivateRoute condition={!session.sessionActive && isAuth} path="/get-charging" component={GetCharging} />
          <Redirect from="*" to="/" />
        </Switch>
      </UiLayout>
      {/* <ChargingTypePopup show={showChargingPopup} /> */}
    </>
  );
});

export default inject('store')(withRouter(General));
