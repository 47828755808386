import React from 'react';
import { Popup } from '../Popup/Popup';
import { UiButton } from '../../ui';
import { GeMapLocationDetails } from '../../store/generated-api';

interface Props {
  show: boolean;
  onCloseModal: () => void;
  locationInfo?: GeMapLocationDetails;
}

export class DepositInfoPopup extends React.Component<Props> {
  render() {
    const { show, onCloseModal, locationInfo } = this.props;
    if (!locationInfo) {
      return null;
    }
    const { deposit } = locationInfo;
    if (!deposit) {
      return null;
    }
    return (
      <Popup show={show} onCloseModal={onCloseModal}>
        <h3 className="subtitle mb-8">Депозит</h3>
        <p className="textGray-40">
          Заблокируем {deposit.amount}
          {deposit.currencySymbol} на вашей карте и вернем их после завершения аренды
        </p>
        <UiButton className="btn_widthFull mt-4" onClick={onCloseModal}>
          Понятно
        </UiButton>
      </Popup>
    );
  }
}
