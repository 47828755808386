import { Configuration, _Api } from './generated-api';
import { basePath } from './config';

export class Api {
  private instance: _Api;
  private getInstance = (accessToken?: string) => new _Api(new Configuration({ accessToken, basePath }));

  constructor(accessToken?: string) {
    this.instance = this.getInstance(accessToken);
  }

  init(accessToken: string) {
    this.instance = this.getInstance(accessToken);
  }

  get client() {
    return this.instance;
  }
}
