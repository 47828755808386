import React from 'react';
import cn from 'classnames';
import { UiLink } from './UiLink';

type MenuLink = {
  label: string;
  to?: string;
  private?: boolean;
  isDivided: boolean;
  logout?: boolean;
};

type Props = {
  links: MenuLink[];
  handleLogout?: () => void;
  secure: boolean;
};

export const UiMenuLinks = ({ secure, links, handleLogout }: Props) => (
  <ul className="bNavLinks">
    {links.map(link => {
      const privateLink = secure && link.private;
      return (
        <li key={link.label} className={cn('bNavLinks__item', { bNavLinks__item_divided: link.isDivided })}>
          {link.to ? (
            <UiLink
              className={cn('bNavLinks__link', { _disabled: privateLink })}
              to={link.to}
              onClick={e => privateLink && e.preventDefault()}
            >
              {link.label}
            </UiLink>
          ) : (
            <div className={cn('bNavLinks__link', { _disabled: privateLink })} onClick={handleLogout}>
              {link.label}
            </div>
          )}
        </li>
      );
    })}
  </ul>
);
