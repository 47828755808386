import React from 'react';
import cn from 'classnames';
import { withRouter, RouteComponentProps } from 'react-router-dom';

interface Props extends RouteComponentProps<any> {
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
  link?: string;
  type?: string;
  onClick?: () => void;
}

const Button = ({ history, children, className, disabled, link, onClick, type = 'button' }: Props) => (
  <button
    className={cn('btn', className)}
    disabled={disabled}
    type={type}
    onClick={link ? () => history.push(link) : onClick}
  >
    {children}
  </button>
);

export const UiButton = withRouter(Button);
