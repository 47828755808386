import React from 'react';
import { normalizePhone, countdown } from '../../utils';
import { FormikContext, connect } from 'formik';
import { RepeatSendCodeProps } from './SignInForm';
import { Timer } from '../../components';
import { observer } from 'mobx-react';

interface FormikPartProps {
  formik: FormikContext<any>;
}

interface Props extends RepeatSendCodeProps {
  buttonActive: boolean;
  executeRecaptcha?: (action: string) => Promise<string>;
}

const RepeatSendComponent = observer(
  ({ formik, sendCode, repeatSend, buttonActive, executeRecaptcha }: FormikPartProps & Props) => {
    const phone = normalizePhone(formik.values.phone);
    const complete = repeatSend && repeatSend.complete;

    return (
      <>
        {buttonActive && (
          <a
            href="#"
            className={!complete ? '_disabled' : ''}
            onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
              e.preventDefault();
              executeRecaptcha &&
                executeRecaptcha('sendCode').then(res => {
                  const token = res;
                  complete && token && sendCode && sendCode(phone, token);
                });
            }}
          >
            Перезвонить ещё раз
          </a>
        )}
        {!complete && (
          <div className="mt-16 textGray-40">
            {buttonActive && 'Запрос отправлен. '}Мы можем повторно перезвонить вам через{' '}
            {<Timer fn={countdown} value={repeatSend!.time} duration={repeatSend!.duration} cb={repeatSend!.reset} />}{' '}
            сек.
          </div>
        )}
      </>
    );
  },
);

export const RepeatSend = connect<Props>(RepeatSendComponent);
