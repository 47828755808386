import React from 'react';

type Props = {
  children: React.ReactNode;
};

export const UiHeader = ({ children }: Props) => (
  <header className="bHeader">
    <div className="container bHeader__container">
      <nav className="bHeader__nav">{children}</nav>
    </div>
  </header>
);
