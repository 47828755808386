import { reduce, get, isPlainObject, isEqual, isString } from 'lodash';

export const diffObjectLike = (obj1: any, obj2: any) =>
  reduce(
    obj1,
    (result: any, value, key) => {
      if (isPlainObject(value)) {
        result[key] = diffObjectLike(value, obj2[key]);
      } else if (!isEqual(value, obj2[key])) {
        result[key] = value;
      }
      return result;
    },
    {},
  );

export const objectParamToNumber = (data: any, param: string) => {
  if (get(data, param)) {
    return {
      ...data,
      [param]: isString(data[param]) ? Number(data[param]) : data[param],
    };
  }
  return data;
};
