import { types, Instance, getSnapshot, applySnapshot, SnapshotIn } from 'mobx-state-tree';

export const NavigationItem = types
  .model({
    id: types.identifier,
    title: types.optional(types.string, 'Назад'),
    to: types.optional(types.string, '/'),
    step: types.optional(types.number, 1),
  })
  .actions(self => {
    let initialState = {};

    const changeModel = (data: any) => {
      self.title = data.title;
      self.to = data.to;
      self.step = data.step;
    };
    const goBack = () => {
      self.step -= 1;
    };
    const afterCreate = () => {
      initialState = getSnapshot(self);
    };
    const reset = () => {
      applySnapshot(self, initialState);
    };

    return {
      changeModel,
      goBack,
      afterCreate,
      reset,
    };
  });

export const Navigation = types
  .model({
    data: types.array(NavigationItem),
    selected: types.reference(NavigationItem),
  })
  .actions(self => {
    let initialState = {};

    const changeSelected = (id: any) => {
      self.selected = id;
    };

    const afterCreate = () => {
      initialState = getSnapshot(self);
    };
    const reset = () => {
      applySnapshot(self, initialState);
    };

    return {
      changeSelected,
      afterCreate,
      reset,
    };
  });

export type INavigationItem = Instance<typeof NavigationItem>;
export type INavigation = Instance<typeof Navigation>;
