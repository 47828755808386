import React from 'react';
import cn from 'classnames';

type Props = {
  color?: 'green' | 'red';
  children: React.ReactNode;
};

export const UiText = ({ color, children }: Props) => (
  <span className={cn({ textGreen: color === 'green', textRed: color === 'red' })}>{children}</span>
);
