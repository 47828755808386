import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { UiContainer, UiSpinner } from '../../ui';
import { GetChargingCheck } from './GetChargingCheck';
import { GetChargingConfirm } from './GetChargingConfirm';
import { inject, observer } from 'mobx-react';
import { IStore } from '../../store/modules';

type Props = {
  store?: IStore;
};

@inject('store')
@observer
export class GetCharging extends React.Component<Props & RouteComponentProps> {
  componentDidMount() {
    const { navigation } = this.props.store!;
    navigation.changeSelected('session');
  }

  componentWillUnmount() {
    this.props.store!.session.resetCheck();
    this.props.store!.resetToDefaultNav();
  }

  componentDidUpdate(prevProps: Props) {
    const { session, navigation } = this.props.store!;
    const { navigation: navPrev } = prevProps.store!;

    if (navPrev.selected.step !== navigation.selected.step && navigation.selected.step === 1) {
      session.resetCheck();
    }
  }

  render() {
    const { session, navigation } = this.props.store!;

    const isPaymentError =
      session.check.error_code === '2' ||
      session.check.error_code === '5' ||
      session.check.error_code === '8' ||
      session.check.error_code === '9';

    return (
      <>
        <UiContainer>
          {navigation.selected.step === 1 ? (
            <GetChargingCheck
              onSubmit={session.checkRequest}
              error={session.check.error_description}
              resetRequestErrors={session.resetCheck}
              isPaymentError={isPaymentError}
              onUpdatePaymentMethod={() => this.props.history.push('/payment-method')}
            />
          ) : (
            <GetChargingConfirm
              data={session.check}
              error={session.check.error_description}
              confirm={session.getDevice}
              isPaymentError={isPaymentError}
              onUpdatePaymentMethod={() => this.props.history.push('/payment-method')}
            />
          )}
        </UiContainer>
        {session.loading && <UiSpinner />}
      </>
    );
  }
}
