import React from 'react';

import { Checkbox } from '../../../components';

export const SignInStepOne = () => (
  <Checkbox
    name="confirmation"
    label={
      <>
        Я согласен с{' '}
        <a href="https://getenergy24.ru/content/public_oferta_rus.pdf" target="_blank">
          условиями использования сервиса
        </a>
      </>
    }
  />
);
