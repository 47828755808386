import { types, Instance, flow, getParent, SnapshotIn, getSnapshot, applySnapshot } from 'mobx-state-tree';
import { omitBy, isNil, isNumber, isEmpty, some, every } from 'lodash';
import { api } from '..';
import { objectParamToNumber } from '../../utils';

const CardInfoData = types
  .model({
    card_holder: types.optional(types.string, ''),
    card_number: types.optional(types.string, ''),
  })
  .actions(self => ({
    reset() {
      const { resetSubState } = getParent(self);
      resetSubState(self);
    },
  }));

const ProfileData = types
  .model({
    name: types.optional(types.string, ''),
    dtype: types.optional(types.string, ''),
    email: types.optional(types.string, ''),
    phone: types.optional(types.string, ''),
  })
  .preProcessSnapshot(snapshot => ({
    ...snapshot,
    dtype: isNumber(snapshot.dtype) ? String(snapshot.dtype) : snapshot.dtype,
  }))
  .actions(self => ({
    reset() {
      const { resetSubState } = getParent(self);
      resetSubState(self);
    },
  }));

export const Profile = types
  .model({
    data: types.optional(ProfileData, {}),
    cardInfo: types.optional(CardInfoData, {}),
    loading: types.optional(types.boolean, false),
  })
  .views(self => ({
    get root(): any {
      return getParent(self);
    },
    get fullInfo(): boolean {
      return some(self.data, isEmpty) ? false : true;
    },
    get cardInfoExist(): boolean {
      return every(self.cardInfo, isEmpty) ? false : true;
    },
  }))
  .actions(self => {
    let initialState = {};

    const afterCreate = () => {
      initialState = getSnapshot(self);
    };
    const reset = () => {
      applySnapshot(self, initialState);
    };

    const resetSubState = (subState: any) => {
      const subStateName = subState.$treenode.subpath;
      const _this = self as any;
      _this[subStateName] = {};
    };

    const getProfile = flow(function* getProfile() {
      self.loading = true;
      try {
        const res = yield api.client.mobileGetUser(self.root.headerToken);
        const { payment_methods, ...resp } = res;
        const cardInfo = payment_methods && payment_methods.find((method: any) => method.is_default);
        const cardInfoData = {
          card_holder: cardInfo && cardInfo.holder,
          card_number: cardInfo && cardInfo.number,
        };

        self.data = {
          ...self.data,
          ...omitBy(resp, isNil),
        };
        self.cardInfo = {
          ...self.cardInfo,
          ...omitBy(cardInfoData, isNil),
        };
      } catch (err) {
        console.error('Failed request ', err);
      }
      self.loading = false;
    });

    const editProfile = flow(function* editProfile(data) {
      const newData = objectParamToNumber(data, 'dtype');
      // self.loading = true;
      try {
        yield api.client.mobileEditUser(newData, self.root.headerToken);
        self.data = {
          ...self.data,
          ...data,
        };
      } catch (err) {
        console.error('Failed request ', err);
      }
      // self.loading = false;
    });

    // tslint:disable-next-line: variable-name
    const cardAttach = flow(function* cardAttach(
      card_cryptogram_packet: string,
      cardholder_name: string,
      payment_method: string = 'cp_russia_tks',
    ) {
      return yield api.client.cardAttachCp(
        { card_cryptogram_packet, cardholder_name, payment_method },
        self.root.headerToken,
      );
    });

    const cardDetach = flow(function* cardDetach() {
      try {
        yield api.client.cardDetachCp(self.root.headerToken);
        self.cardInfo.reset();
      } catch (err) {
        console.error('Failed request ', err);
      }
    });

    return {
      afterCreate,
      reset,
      resetSubState,
      getProfile,
      editProfile,
      cardAttach,
      cardDetach,
    };
  });

export type ICardInfoData = Instance<typeof CardInfoData>;
export type IProfileData = Instance<typeof ProfileData>;
export type IProfileDataSnap = SnapshotIn<typeof ProfileData>;
export type IProfile = Instance<typeof Profile>;
