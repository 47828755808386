import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import { IconLogoGetEnergy } from './Icons';

type Props = typeof defaultProps;

const defaultProps = Object.freeze({
  inMenu: false,
});

export const UiHeaderLogo = ({ inMenu }: Props) => (
  <Link className={cn('bLogo', { bMainMenu__logo: inMenu })} to="/">
    <IconLogoGetEnergy className="icon icon_sizeFull" />
  </Link>
);

UiHeaderLogo.defaultProps = defaultProps;
