import React from 'react';
import InputMask from 'react-input-mask';
import { FieldProps, Field } from 'formik';
import { UiInput } from '../../ui';

interface InputProps {
  placeholder?: string;
  type?: string;
  required?: boolean;
  disabled?: boolean;
  readonly?: boolean;
  error?: string;
  className?: string;
  maxLength?: number;
}

interface Props extends InputProps {
  name: string;
  label: string;
}

const renderInput = ({ field, form }: FieldProps, label: string, props: InputProps) => {
  const { disabled, readonly, required, type = 'text', error, ...otherProps } = props;

  const errorProps = {
    error: (form.touched[field.name] && form.errors[field.name]) || !!error,
    errorMsg: form.errors[field.name] || error,
  };

  switch (type) {
    case 'phone':
      return (
        <InputMask
          {...field}
          mask="+7 (999) 999-99-99"
          maskChar={null}
          alwaysShowMask={false}
          readOnly={readonly}
          disabled={disabled}
        >
          {(inputProps: any) => (
            <UiInput
              {...inputProps}
              {...otherProps}
              {...errorProps}
              type="tel"
              label={label}
              placeholder="+7 (XXX) XXX-XX-XX"
              disabled={disabled}
              readOnly={readonly}
              required={required}
            />
          )}
        </InputMask>
      );
    case 'number':
      return (
        <UiInput
          {...field}
          {...otherProps}
          {...errorProps}
          onChange={(e: any) => {
            const { value } = e.target;
            const cleanedValue = value.replace(/[A-Za-z-\.,;:'"!?]/g, '');
            e.target.value = cleanedValue;
            field.onChange(e);
          }}
          label={label}
          type={type}
          disabled={disabled}
          readOnly={readonly}
          required={required}
        />
      );
    default:
      return (
        <UiInput
          {...field}
          {...otherProps}
          {...errorProps}
          label={label}
          type={type}
          disabled={disabled}
          readOnly={readonly}
          required={required}
        />
      );
  }
};

export const Input = ({ name, label, ...props }: Props) => (
  <Field name={name}>{({ field, form }: FieldProps) => renderInput({ field, form }, label, props)}</Field>
);
