import React from 'react';
import cn from 'classnames';

type Props<T> = { [K in keyof T]: T[K] };

interface Prop {
  [key: string]: any;
}

export const UiInput = <T extends Prop>({ label, className, error, errorMsg, ...rest }: Props<T>) => (
  <label className="mb-16">
    {label && <span className="label">{label}</span>}
    <input {...rest} className={cn('input', className, { hasError: error })} />
    {error && <span className="inputError">{errorMsg}</span>}
  </label>
);
