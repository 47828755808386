import React from 'react';
import cn from 'classnames';
import { some } from 'lodash';
import { Field, FieldProps } from 'formik';

interface MainProps {
  label?: string;
  name: string;
  size?: number;
  values?: string[];
  disableInputError?: boolean;
}

type Props<T> = { [K in keyof T]: T[K] };

interface Prop {
  [key: string]: any;
}

export const UiCodeInput = <T extends Prop>({
  label,
  values,
  size,
  name,
  className,
  disableInputError,
  error,
  errorMsg,
  ...rest
}: Props<T> & MainProps) => (
  <div className="mb-24">
    {label && <span className="label">{label}</span>}
    {values && size! > 0 && (
      <Field name={name}>
        {({ field, form }: FieldProps) => {
          const currentError = (!disableInputError && some(form.touched, Boolean) && form.errors[field.name]) || error;
          const currentErrorMsg = (!disableInputError && form.errors[field.name]) || errorMsg;
          return (
            <>
              <div className={cn('bSmsCodeInput', className, { hasError: currentError })}>
                {values.map((item: string, index: number) => (
                  <UiCodeInputField {...rest} size={size} field={field} form={form} key={index} index={index} />
                ))}
              </div>
              {currentError && <span className="inputError textCenter">{currentErrorMsg}</span>}
            </>
          );
        }}
      </Field>
    )}
  </div>
);

const UiCodeInputField = <T extends Prop>({ field, form, index, onKeyDown, onChange, size, ...rest }: Props<T>) => (
  <input
    {...field}
    {...rest}
    value={field.value[index] || ''}
    name={`${field.name}-${index}`}
    key={`${field.name}-${index}`}
    className="input bSmsCodeInput__item"
    type="number"
    placeholder="X"
    pattern="[0-9]*"
    maxLength={1}
    autoComplete="off"
    onFocus={(e: any) => {
      const fieldLength = field.value.replace(/\s+/g, '').length;
      const currentFieldValue = field.value[index];
      const fieldValueCompense = currentFieldValue ? field.value[index].replace(/\s+/g, '') : currentFieldValue;
      const firstElement = document.querySelector(`input[name=${field.name}-${fieldLength}]`) as HTMLInputElement;
      const firstElementValue = firstElement && firstElement.value ? firstElement.value.replace(/\s+/g, '') : '';
      !fieldValueCompense && firstElement && !firstElementValue && fieldLength < size
        ? firstElement.focus()
        : e.target.focus(e);
    }}
    onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => onKeyDown && onKeyDown(e, index, { field, form })}
    onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange && onChange(e, index, { field, form })}
  />
);
