import moment from 'moment';
import 'moment/locale/ru';

interface OpeningHours {
  start?: string;
  end?: string;
}

export const openingHours = ({ start, end }: OpeningHours, format: string = 'H:mm') => {
  if (!start || !end) return;

  const startWork = moment(start)
    .utc()
    .local()
    .format(format);
  const endWork = moment(start)
    .utc()
    .local()
    .add(moment(end).diff(moment(start), 'hours'), 'hours')
    .format(format);
  return `с ${startWork} до ${endWork}`;
};

export const isActiveNow = ({ start, end }: OpeningHours) => {
  if (!start || !end) return true;
  const startWork = moment()
    .utc()
    .local()
    .set(
      'hours',
      moment(start)
        .utc()
        .local()
        .get('hours'),
    );
  const endWork = moment(startWork).add(moment(end).diff(moment(start), 'hours'), 'hours');

  const now = moment()
    .utc()
    .local();
  return now.isBetween(startWork, endWork);
};

export const fullFormat = 'DD.MM.YYYY в HH:mm';

export const formatDate = (date?: Date) => {
  return moment(date).format(fullFormat);
};

export const timesRemaining = (end?: Date) => {
  const diff = moment(end).diff(moment());
  const dur = moment.duration(diff);

  return calcDiddTime(dur);
};

export const periodTimer = (startTime?: Date) => {
  const diff = moment().diff(moment(startTime), 'minutes', true);
  const dur = moment.duration(Math.ceil(diff), 'minutes');

  return calcDiddTime(dur);
};

export const countdown = (startTime: string, duration: number) => {
  const diff = moment(startTime)
    .add('minutes', duration)
    .diff(moment(), 'seconds', true);

  return Math.ceil(diff);
};

export const timeToUse = (value?: number) => {
  if (value) {
    const dur = moment.duration(value, 'minutes');
    return calcDiddTime(dur);
  }
  return '0 мин';
};

const calcDiddTime = (dur: moment.Duration) => {
  const days = dur.days();
  const hours = dur.hours();
  const min = dur.minutes();

  let fullDiffTime = '';

  if (min > 0) {
    fullDiffTime = ` ${min} мин`;
  }
  if (hours > 0) {
    fullDiffTime = ` ${hours} ч${fullDiffTime}`;
  }
  if (days > 0) {
    fullDiffTime = `${days} д${fullDiffTime}`;
  }
  return min === 0 && hours === 0 && days === 0 ? 'меньше минуты' : fullDiffTime;
};
