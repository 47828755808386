import * as Yup from 'yup';

const incorrectFormat = 'Неверный формат';

Yup.setLocale({
  string: {
    email: incorrectFormat,
    min: 'Минимальная длина поля ${min}',
    matches: incorrectFormat,
    length: 'Поле должно содержать ${length} символа',
  },
  mixed: {
    required: 'Обязательное поле',
  },
});

export const validationYup = (obj: any) => Yup.object().shape(obj);
