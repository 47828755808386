export class AppStorage {
  ls?: Storage;

  constructor() {
    try {
      this.ls = window.localStorage;
    } catch (e) {
      console.error('LocalStorage storage is not available', e);
    }
  }

  save(key: string, value: string) {
    try {
      this.ls && this.ls.setItem(key, value);
    } catch (e) {
      console.error('LocalStorage set error:', e);
    }
  }

  get(key: string) {
    try {
      return this.ls && (this.ls.getItem(key) || undefined);
    } catch (e) {
      console.error('LocalStorage set error:', e);
    }
  }

  remove(key: string) {
    try {
      this.ls && this.ls.removeItem(key);
    } catch (e) {
      console.error('LocalStorage remove error:', e);
    }
  }
}
