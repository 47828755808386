import React, { FC, useState, useEffect } from 'react';
import { DepositInfoPopup } from './DepositInfoPopup';
import { api } from '../../store';
import { GeMapLocationDetails } from '../../store/generated-api';
import { QuestionMark } from '../../ui';

interface DepositInfoProps {
  locationId: number;
}

const DepositInfo: FC<DepositInfoProps> = ({ locationId }) => {
  const [infoVisible, setInfoVisible] = useState(false);
  const [locationInfo, setLocationInfo] = useState<GeMapLocationDetails>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    api.client
      .getDeviceDetails(locationId)
      .then(res => {
        setLocationInfo(res);
      })
      .finally(() => setIsLoading(false));
  }, []);

  if (isLoading || !locationInfo || !locationInfo.deposit) {
    return null;
  }

  return (
    <div>
      <DepositInfoPopup locationInfo={locationInfo} show={infoVisible} onCloseModal={() => setInfoVisible(false)} />
      <div
        style={{
          borderRadius: '8px',
          background: '#F2F2F2',
          width: '100%',
          padding: '12px',
          marginTop: '8px',
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
        }}
        onClick={() => setInfoVisible(true)}
      >
        {`Депозит - ${locationInfo.deposit.amount} ${locationInfo.deposit.currencySymbol}`}
        <div style={{ marginLeft: '8px' }} />
        <QuestionMark />
      </div>
    </div>
  );
};

export default DepositInfo;
