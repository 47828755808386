import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router-dom';

import { api } from '../../store';
import { IStore } from '../../store/modules';
import { UiContainer, UiHeadline, UiSpinner } from '../../ui';
import { PaymentMethodExist, PaymentMethodNotExist } from '.';

const ApplePaySession = (window as any).ApplePaySession;

const GooglePay = {
  allowedPaymentMethods: ['CARD', 'TOKENIZED_CARD'],
  allowedCardNetworks: ['MASTERCARD', 'VISA'],
  tokenizationParameters: {
    tokenizationType: 'PAYMENT_GATEWAY',
    parameters: {
      gateway: 'cloudpayments',
      gatewayMerchantId: process.env.REACT_APP_CP_PUBLIC_ID,
    },
  },
};

interface Props {
  store?: IStore;
}

interface State {
  canUseApplePay: boolean;
  canUseGooglePay: boolean;
  error?: string;
}

@inject('store')
@observer
export class PaymentMethod extends Component<Props & RouteComponentProps> {
  win3ds?: Window = undefined;
  googlePay: any;

  state: State = {
    canUseApplePay: false,
    canUseGooglePay: false,
  };

  componentDidMount() {
    try {
      ApplePaySession && this.setState({ canUseApplePay: ApplePaySession.canMakePayments() });
    } catch (e) {
      console.error(e);
    }

    const googlePayScript = document.createElement('script');
    googlePayScript.async = true;
    googlePayScript.src = 'https://pay.google.com/gp/p/js/pay.js';
    googlePayScript.onload = () => {
      // @ts-ignore
      if (!google) return;
      // @ts-ignore
      this.googlePay = new google.payments.api.PaymentsClient({ environment: 'PRODUCTION' });

      this.googlePay
        .isReadyToPay({ allowedPaymentMethods: GooglePay.allowedPaymentMethods })
        .then((res: any) => this.setState({ canUseGooglePay: Boolean(res.result) }));
    };
    document.body.appendChild(googlePayScript);
  }

  handleApplePay = () => {
    const session = new (window as any).ApplePaySession(1, {
      countryCode: 'RU',
      currencyCode: 'RUB',
      supportedNetworks: ['visa', 'masterCard'],
      merchantCapabilities: ['supports3DS'],
      total: { label: 'Test payment', amount: '1.00' },
    });

    session.onvalidatemerchant = (e: any) =>
      api.client
        .applepaysession({ validationUrl: e.validationURL }, this.props.store!.headerToken)
        .then((result: any) => session.completeMerchantValidation(result));

    session.onpaymentauthorized = async (e: any) => {
      try {
        await this.handleAttach({ packet: e.payment.token }, 'APPLE PAY');
        session.completePayment(ApplePaySession.STATUS_SUCCESS);
      } catch (e) {
        session.completePayment(ApplePaySession.STATUS_FAILURE);
      }
    };

    session.begin();
  };

  handleGooglePay = () => {
    this.googlePay
      .loadPaymentData({
        merchantId: process.env.REACT_APP_GOOGLE_MERCHANT_ID,
        paymentMethodTokenizationParameters: GooglePay.tokenizationParameters,
        allowedPaymentMethods: GooglePay.allowedPaymentMethods,
        cardRequirements: {
          allowedCardNetworks: GooglePay.allowedCardNetworks,
        },
        transactionInfo: {
          currencyCode: 'RUB',
          totalPriceStatus: 'FINAL',
          totalPrice: '1.00',
        },
      })
      .then((paymentData: any) => {
        this.handleAttach({ packet: JSON.stringify(paymentData.paymentMethodToken.token) }, 'GOOGLE PAY');
      });
  };

  async handleAttach(result: { packet: string }, cardholderName: string) {
    const { profile } = this.props.store!;

    this.setState({ error: undefined });

    try {
      const res = await profile.cardAttach(result.packet, cardholderName);
      const data = await res.json();

      if (data === 'OK') {
        this.handleSuccess();
      }

      if (typeof data === 'object' && data.AcsUrl) {
        const token = this.props.store!.user.token;

        this.win3ds = window.open() as Window;

        window.addEventListener('message', this.handle3dsSuccess);

        this.win3ds.document.body.innerHTML = `
          <form name="downloadForm" action="${data.AcsUrl}" method="POST">
            <input type="hidden" name="PaReq" value="${data.PaReq}">
            <input type="hidden" name="MD" value="${data.TransactionId}">
            <input type="hidden" name="TermUrl" value="${
              process.env.REACT_APP_API_URL
            }/mobile/card_3ds?appType=web&token=${token}">
          </form>
        `;
        // @ts-ignore
        this.win3ds.downloadForm.submit();
      }
    } catch (res) {
      const data = await res.json();
      if (data && data.error) this.setState({ error: data.error });
    }
  }

  handle3dsSuccess = (e: MessageEvent) => {
    const data = JSON.parse(e.data || null);

    if (!data) return;

    if (data.success) this.handleSuccess();
    else this.setState({ error: data.error });

    window.removeEventListener('message', this.handle3dsSuccess);
    this.win3ds && this.win3ds.close();
  };

  handleSuccess = () => {
    this.props.store!.profile.getProfile();
  };

  handleEdit = () => this.props.history.push('/payment-method/edit');

  render() {
    const { profile, session } = this.props.store!;
    const { canUseApplePay, canUseGooglePay, error } = this.state;

    return (
      <UiContainer className="container_flex">
        <UiHeadline>Метод оплаты</UiHeadline>
        {profile.loading ? (
          <UiSpinner />
        ) : profile.cardInfoExist ? (
          <PaymentMethodExist
            cardInfo={profile.cardInfo}
            activeSession={session.sessionActive}
            error={error}
            onApplePay={false ? this.handleApplePay : undefined}
            onGooglePay={canUseGooglePay ? this.handleGooglePay : undefined}
            onReattach={this.handleEdit}
            onDetach={profile.cardDetach}
          />
        ) : (
          <PaymentMethodNotExist
            store={this.props.store!}
            error={error}
            onAttach={this.handleEdit}
            onApplePay={false ? this.handleApplePay : undefined}
            onGooglePay={canUseGooglePay ? this.handleGooglePay : undefined}
          />
        )}
      </UiContainer>
    );
  }
}
