import React, { RefObject, createRef } from 'react';
import { difference } from 'lodash';
import { FieldProps, connect, FormikContext, getIn } from 'formik';
import { arraySize } from '../../utils';
import { UiCodeInput } from '../../ui';

interface FormikPartProps {
  formik: FormikContext<any>;
}

type Props = {
  label?: string;
  name: string;
  error?: string;
} & Partial<DefaultProps>;

type DefaultProps = Readonly<typeof defaultProps>;

const defaultProps = {
  size: 4,
};

const BACKSPACE_KEY = 8;
const LEFT_ARROW_KEY = 37;
const UP_ARROW_KEY = 38;
const RIGHT_ARROW_KEY = 39;
const DOWN_ARROW_KEY = 40;
const E_KEY = 69;

class Code extends React.Component<FormikPartProps & Props> {
  static readonly defaultProps = defaultProps;

  replaceAt = (string: string, index: number, replace: string) => {
    return string.substring(0, index) + replace + string.substring(index + 1);
  };

  handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number, { field, form }: FieldProps) => {
    const { size } = this.props;
    const { setFieldValue } = form;

    let value = String(e.target.value);

    value = value.replace(/[^\d]/g, '');

    if (value !== '') {
      if (value.length > 1) {
        const replaceVal = difference(value.split(''), [field.value[index]]).join();
        replaceVal && setFieldValue(field.name, this.replaceAt(field.value, index, replaceVal));
      } else {
        setFieldValue(field.name, this.replaceAt(field.value, index, value));
      }

      const newTarget = index < size! - 1 ? (e.target.nextSibling as HTMLInputElement) : e.target;

      if (newTarget) {
        newTarget.focus();
        // newTarget.select();
      }
    }
  };

  handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number, { field, form }: FieldProps) => {
    const target = e.currentTarget;
    const nextTarget = target.nextSibling as HTMLInputElement;
    const prevTarget = target.previousSibling as HTMLInputElement;

    switch (e.keyCode) {
      case BACKSPACE_KEY:
        e.preventDefault();
        target.value = '';
        if (field.value[index] === ' ') {
          if (prevTarget) {
            prevTarget.focus();
            // prevTarget.select();
          }
        }
        form.setFieldValue(field.name, this.replaceAt(field.value, index, ' '));
        break;

      case LEFT_ARROW_KEY:
        e.preventDefault();
        if (prevTarget) {
          prevTarget.focus();
          // prevTarget.select();
        }
        break;

      case RIGHT_ARROW_KEY:
        e.preventDefault();
        if (nextTarget) {
          nextTarget.focus();
          // nextTarget.select();
        }
        break;

      case UP_ARROW_KEY:
      case DOWN_ARROW_KEY:
      case E_KEY:
        e.preventDefault();
        break;

      default:
        break;
    }
  };

  render() {
    const { label, name, size, error, formik } = this.props;
    const code = getIn(formik.values, name);
    const codeArray = arraySize({ size, value: code });
    return (
      <UiCodeInput
        name={name}
        label={label}
        size={size}
        values={codeArray}
        onKeyDown={this.handleKeyDown}
        onChange={this.handleChange}
        error={!!error}
        errorMsg={error}
        disableInputError
      />
    );
  }
}

export const CodeInput = connect<Props>(Code);
