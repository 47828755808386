type Props<T> = { [K in keyof T]: T[K] };

interface Prop {
  [key: string]: string[];
}

const declencions: Props<Prop> = {
  day: ['день', 'дня', 'дней'],
  hour: ['час', 'часа', 'часов'],
  min: ['минута', 'минуты', 'минут'],
  sec: ['секунда', 'секунды', 'секунд'],
  left: ['остался', 'осталось', 'осталось'],
  charging: ['зарядник', 'зарядника', 'зарядников'],
};

export const decOfNum = (n: number, titles = 'charging') =>
  declencions[titles][
    n % 10 === 1 && n % 100 !== 11 ? 0 : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20) ? 1 : 2
  ];
