import React from 'react';
import { formatDate, timeToUse, timesRemaining } from '../../utils';
import { InfoCardTimer } from './InfoCardTimer';
import { UiButton } from '../../ui';
import { GeSession, GeLastsession } from '../../store/generated-api';

interface Props {
  info: GeSession;
  onShowHelper: (type: string) => void;
  minimized?: boolean;
  closed?: boolean;
}

export const InfoCardSession = ({ info, closed, onShowHelper, minimized }: Props) => {
  const convertToCaptonCN = minimized ? 'caption textGray-40' : 'subtitle mb-4 mt-16';
  const compenseSum = (value?: number) => `${value || 0} P`;
  const closedInfo = info as GeLastsession;
  return (
    <>
      <table className="bMap__table">
        <tbody>
          <tr className="cardSession__isUsed cardSession__main">
            <th scope="row">
              <div className="subtitle mb-4">{!closed ? 'Используется' : 'Был в пользовании'}</div>
            </th>
            <td>
              <div className="subtitle mb-4">
                {!closed ? (
                  <InfoCardTimer time={info.session_start_time ? new Date(info.session_start_time) : undefined} />
                ) : (
                  timeToUse(closedInfo.session_duration)
                )}
              </div>
            </td>
          </tr>
          <tr>
            <th scope="row">
              <div className="caption textGray-40 mb-16">Взят в аренду</div>
            </th>
            <td>
              <div className="caption textGray-40 mb-4">
                {info.session_start_time ? formatDate(new Date(info.session_start_time)) : ''}
              </div>
            </td>
          </tr>
          <tr>
            <th scope="row">
              <div className="subtitle mb-16">Оплачено</div>
            </th>
            <td>
              <div className="subtitle mb-4">{compenseSum(info.paid_suc)}</div>
            </td>
          </tr>
          {info.paid_unsuc! > 0 && (
            <tr>
              <th scope="row">
                <div className="subtitle mb-16">Не оплачено</div>
              </th>
              <td>
                <div className="subtitle mb-4">{compenseSum(info.paid_unsuc)}</div>
              </td>
            </tr>
          )}
          {!closed && (
            <>
              <tr className="hr cardSession__nextPayment cardSession__main">
                <th scope="row">
                  <div className={convertToCaptonCN}>Следующий платёж</div>
                </th>
                <td>
                  <div className={convertToCaptonCN}>{compenseSum(info.amount_to_pay)}</div>
                </td>
              </tr>
              <tr>
                <th scope="row">
                  <div className="caption textGray-40 mb-4" />
                </th>
                <td>
                  <div className="caption textGray-40 mb-4">
                    {info.time_to_pay ? formatDate(new Date(info.time_to_pay)) : null}
                  </div>
                </td>
              </tr>
            </>
          )}
        </tbody>
      </table>
      {!closed && <hr />}
      <UiButton className="btn_borderGreen btn_widthFull mt-4" onClick={() => onShowHelper('return')}>
        {!closed ? 'Как Вернуть' : 'Закрыть'}
      </UiButton>
    </>
  );
};
