import React from 'react';
import cn from 'classnames';
import { omit } from 'lodash';
import { UiButton } from '../../ui';

interface ButtonExt {
  link?: string;
  onClick?: () => void;
  modif?: string;
}

interface Button extends ButtonExt {
  title: string;
}

export interface InfoCardSimpleProps {
  content?: JSX.Element;
  btns?: Button | Button[];
}

interface Props extends InfoCardSimpleProps {
  theme?: 'bgGreen' | 'bgRed';
  closeAll?: () => void;
}

const btnRender = (props: Button, theme?: 'bgGreen' | 'bgRed', key?: string) => {
  const { modif, title, ...otherProps } = props;
  const btnClassName = theme ? `btn_bgOn${theme.replace(/^bg/g, '')}` : 'btn_borderGreen';
  return (
    <UiButton className={cn('btn_widthFull', btnClassName, modif)} key={key} {...otherProps}>
      {title}
    </UiButton>
  );
};

export const InfoCardSimple = ({ content, btns, closeAll, theme }: Props) => (
  <>
    <p className="textBody-2 textCenter mb-16">{content}</p>
    {btns
      ? Array.isArray(btns)
        ? btns.map((btn, i) => {
            const modif = btns.length !== i + 1 ? 'mb-16' : '';
            return btnRender({ ...btn, modif }, theme, `btnInfoCard-${i}`);
          })
        : btnRender(btns, theme)
      : btnRender({ title: 'Закрыть', onClick: closeAll }, theme)}
  </>
);
