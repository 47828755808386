import React from 'react';
import { Input } from '../../../components';
import { observer, inject } from 'mobx-react';
import { IStore } from '../../../store/modules';

type Props = {
  store?: IStore;
};

export const SignInStepTwo = inject('store')(
  observer(({ store }: Props) => (
    <>
      <p className="textBody-2 textBold textCenter">
        Вы получите входящий звонок с номера +7&nbsp;(***)&nbsp;**&#8209;<span className="textGreen">ХХ</span>&#8209;
        <span className="textGreen">ХХ</span>. Введите последние 4 цифры в качестве кода
      </p>
      <div className="spacer spacer_lg" />
      <Input
        name="code"
        label="Введите код"
        error={store!.user.errors && store!.user.errors.code}
        className="lg"
        maxLength={4}
      />
    </>
  )),
);
