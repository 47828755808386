import React, { Component } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

type Props = { condition: boolean; component: React.ComponentType<any> } & RouteProps;

export class PrivateRoute extends Component<Props> {
  render() {
    const { condition, component: Component, ...rest } = this.props;
    return (
      <Route
        {...rest}
        render={props =>
          condition ? <Component {...props} /> : <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        }
      />
    );
  }
}
