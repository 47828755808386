import { types, Instance, flow, getParent, getSnapshot, SnapshotIn, applySnapshot } from 'mobx-state-tree';
import { isEqual } from 'lodash';
import { api } from '..';

export const SessionCheck = types.model({
  station_short_number: types.maybe(types.number),
  error_code: types.optional(types.string, ''),
  error_description: types.optional(types.string, ''),
  location_address: types.optional(types.string, ''),
  location_name: types.optional(types.string, ''),
  description1: types.optional(types.string, ''),
  description2: types.optional(types.string, ''),
  deposit: types.maybeNull(
    types.model({
      amount: types.optional(types.integer, 0),
      paid: types.optional(types.integer, 0),
      returned: types.optional(types.integer, 0),
      currency: types.optional(types.string, ''),
      currencySymbol: types.optional(types.string, ''),
    }),
  ),
});

export const Session = types
  .model({
    id: types.maybeNull(types.number),
    status: types.maybeNull(types.string),
    check: types.optional(SessionCheck, {}),
    info: types.maybeNull(types.frozen()),
    showHelper: types.maybeNull(types.enumeration('showHelper', ['return', 'closed', 'get'])),
    loading: types.optional(types.boolean, false),
  })
  .views(self => ({
    get root(): any {
      return getParent(self);
    },
    get sessionActive(): boolean {
      return self.info ? self.info.session_status === 'active' : false;
    },
    get paidFail(): boolean {
      return self.info ? self.info.paid_unsuc > 0 : false;
    },
  }))
  .actions(self => {
    let initialState: ISessionSnap = {};

    const resetCheck = () => {
      !isEqual(initialState.check, getSnapshot(self.check)) && applySnapshot(self.check, initialState.check);
    };

    const helperSwitch = (type: typeof self.showHelper) => {
      self.showHelper = type;
    };

    const checkRequest = flow(function* check(code: number) {
      self.loading = true;
      try {
        const resp = yield api.client.mobileCheck({ station_short_number: code }, self.root.headerToken);
        self.check = resp[0];
        self.root.navigation.selected.changeModel({
          title: 'Ввод кода',
          step: 2,
        });
      } catch (err) {
        const error = yield err.json();
        const { error_code, error_description } = error[0];
        if (error_code === '2') {
          self.root.router.push('/payment-method');
        } else {
          self.check = {
            ...self.check,
            error_code,
            error_description,
          };
        }
      }
      self.loading = false;
    });

    const getDevice = flow(function* getDevice() {
      const body = {
        station_short_number: self.check.station_short_number,
        dtype: Number(self.root.profile.data.dtype),
      };
      self.loading = true;
      try {
        const resp = yield api.client.mobileGetDevice(body, self.root.headerToken);
        const { session_id } = resp[0];
        self.id = session_id;
        getSession(session_id);
      } catch (err) {
        const error = yield err.json();
        const { error_code, error_description } = error[0];
        if (error_code === '2') {
          self.root.router.push('/payment-method');
        } else {
          self.check = {
            ...self.check,
            error_code,
            error_description,
          };
        }
        self.loading = false;
      }
    });

    const getSession = flow(function* getSession(id: number) {
      self.loading = true;
      try {
        const resp = yield api.client.mobileGetSession({ session_id: id }, self.root.headerToken);
        const { session_status } = resp[0];
        self.status = session_status;

        if (session_status === 'active') {
          getActiveSession(true);
        } else {
          window.setTimeout(() => {
            self.root.session.getSession(id);
          }, 5000);
        }
      } catch (err) {
        console.error('Failed request ', err);
        self.loading = false;
      }
    });

    const getActiveSession = flow(function* getActiveSession(redirect?: boolean) {
      try {
        const resp = yield api.client.mobileGetActiveSession(self.root.headerToken);
        const { session_status } = resp[0];

        if (session_status === 'active') {
          window.setTimeout(() => {
            self.root.session.getActiveSession();
          }, 5000);
        } else {
          self.sessionActive && getLastSession();
        }

        self.info = resp[0];
        if (redirect) {
          helperSwitch('get');
          self.root.router.replace('/');
          resetCheck();
        }
      } catch (err) {
        console.error('Failed request ', err);
      }
      self.loading = false;
    });

    const getLastSession = flow(function* getLastSession() {
      try {
        const resp = yield api.client.lastsession(self.root.headerToken);
        self.info = resp[0];
        helperSwitch('closed');
      } catch (err) {
        console.error('Failed request ', err);
      }
    });

    const afterCreate = () => {
      initialState = getSnapshot(self);
    };

    return {
      afterCreate,
      helperSwitch,
      resetCheck,
      checkRequest,
      getDevice,
      getSession,
      getActiveSession,
      getLastSession,
    };
  });

export type ISessionCheck = Instance<typeof SessionCheck>;
export type ISessionSnap = SnapshotIn<typeof Session>;
export type ISession = Instance<typeof Session>;
