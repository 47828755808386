import React from 'react';
import { UiButton, UiInfoCardList, ListItemProps } from '../../ui';

const listDefault = [
  { title: 'Подойдите к любому автомату Get Energy' },
  { title: 'Уберите провод внутрь' },
  { title: 'Вставьте зарядник в свободную ячейку' },
  { title: 'Получите сообщение о завершении сессии' },
];

interface Props {
  list?: ListItemProps[];
  type?: 'ordered' | 'iconed';
  onClose?: () => void;
}

export const InfoCardList = ({ list = listDefault, type = 'ordered', onClose }: Props) => (
  <>
    <UiInfoCardList list={list} type={type} />
    <UiButton
      className={`${type === 'ordered' ? 'btn_borderGreen' : 'btn_bgOnGreen'} btn_widthFull mt-4`}
      onClick={() => onClose && onClose()}
    >
      Закрыть
    </UiButton>
  </>
);
