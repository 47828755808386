import React from 'react';
import { FieldProps } from 'formik';
import { ICharging } from '../../store/modules';

interface Props extends FieldProps {
  label: string;
  list: ICharging[];
}

export const Select = ({ field, form: { touched, errors }, ...props }: Props) => (
  <label className="mb-24">
    <span className="label">{props.label}</span>
    <select {...field} className="select">
      {props.list.map(item => (
        <option key={`${field.name}-${item.value}`} value={item.value}>
          {item.label}
        </option>
      ))}
    </select>
    {touched[field.name] && errors[field.name] && <div className="error">{errors[field.name]}</div>}
  </label>
);
