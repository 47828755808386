import React from 'react';
import { observer, inject } from 'mobx-react';
import { IStore } from '../../store/modules';
import { Field } from 'formik';
import { Select, Form, SubmitProps } from '../Form';

interface Props {
  store?: IStore;
}

type Values = {
  selected: string;
};

@inject('store')
@observer
export class ChargingForm extends React.Component<Props> {
  handleSubmit = ({ values }: SubmitProps<Values>) => {
    const { user } = this.props.store!;
    user.setChargingType(values.selected);
  };

  render() {
    const { store } = this.props;

    return (
      <Form initialValues={{ selected: store!.defaultSelected }} onSubmit={this.handleSubmit} btnTitle="Готово">
        <Field name="selected" component={Select} list={store!.chargings} />
      </Form>
    );
  }
}
