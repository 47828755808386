import React from 'react';
import { Popup } from './Popup';
import { ChargingForm } from '../ChargingForm';
import { observer } from 'mobx-react';

export const ChargingTypePopup = observer(props => (
  <Popup show={props.show}>
    <h3 className="headline mt-4 mb-24 textCenter">Выберите тип кабеля зарядника</h3>
    <ChargingForm />
  </Popup>
));
