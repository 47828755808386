import React from 'react';

type Props = {
  isOpen: boolean;
  children: React.ReactNode;
};

export const UiMenu = ({ children, isOpen }: Props) => (
  <div className="bMainMenu" aria-label="Главное меню" id="mainMenu" hidden={!isOpen}>
    <div className="container bMainMenu__inner">{children}</div>
  </div>
);
