import React from 'react';
import { IconPaymentMastercard, IconPaymentVisa, UiButton, UiText, IconPaymentMir } from '../../ui';
import { IStore } from '../../store/modules';

interface Props {
  store: IStore;
  error?: string;
  onAttach: () => void;
  onApplePay?: () => void;
  onGooglePay?: () => void;
}

export class PaymentMethodNotExist extends React.Component<Props> {
  render() {
    const { error, onAttach } = this.props;

    return (
      <>
        <p className="textBody-2">
          Для того чтобы использовать сервис GetEnergy вам необходимо привязать свою карту, для расчётов за аренду
          зарядника.
        </p>
        <UiButton className="btn_widthFull btn_borderGreen mt-24" onClick={onAttach}>
          <IconPaymentVisa />
          <IconPaymentMastercard style={{ height: 48 }} />
          <IconPaymentMir />
        </UiButton>
        {error && (
          <div className="row mb-16">
            <div className="col-12 textCenter">
              <UiText color="red">{error}</UiText>
            </div>
          </div>
        )}
        <UiButton className="btn_borderGreen btn_widthFull mt-16" link="/">
          Привязать позднее
        </UiButton>
      </>
    );
  }
}
