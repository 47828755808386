import React from 'react';
import { GooglePlayButton, AppStoreButton, ButtonsContainer } from 'react-mobile-app-button';

import { Page } from '../../components';

interface Props {}

const APKUrl = 'https://play.google.com/store/apps/details?id=com.getenergy';
const iOSUrl = 'https://apps.apple.com/us/app/get-energy/id1531076906';

export class Placeholder extends React.Component<Props> {
  render() {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          height: '100vh',
          justifyContent: 'center',
        }}
      >
        <Page title="😔 Упс... ">
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <p className="textBody-2">
              Веб версия приложения временно не доступна. <br /> Просьба воспользоваться мобильным приложением.
            </p>
            <p />
            <ButtonsContainer>
              <GooglePlayButton height={60} url={APKUrl} theme={'light'} className={'custom-style'} />
              <AppStoreButton height={60} url={iOSUrl} theme={'light'} className={'custom-style'} />
            </ButtonsContainer>
          </div>
        </Page>
      </div>
    );
  }
}
