import React, { useRef } from 'react';

import { Form, Input, SubmitProps } from '../../components';
import { FormStepProps } from '../../types';
import { normalizePhone } from '../../utils';
import { IRepeatSend } from '../../store/modules';
import { RepeatSend } from './SignInRepeatSend';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

export type RepeatSendCodeProps = {
  sendCode?: (phone: number, token: string) => void;
  repeatSend?: IRepeatSend;
};

type ErrorKeys = 'phone' | 'code';
type Props = {
  isLoading: boolean;
  currentStep: number;
  step: FormStepProps;
  errors?: { [K in ErrorKeys]: string };
  resetRequestErrors?: () => void;
} & RepeatSendCodeProps;

type ISignInFormData = {
  phone: string;
  confirmation: boolean;
  code: string;
  token: string;
};

export const SignInForm: React.FC<Props> = props => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { isLoading, currentStep, step, repeatSend, errors, resetRequestErrors, sendCode } = props;
  const finalStep = currentStep === 2;

  const requestCaptcha = async () => {
    return executeRecaptcha && executeRecaptcha('sendCode');
  };

  const handleSubmit = async ({ values, formikActions }: SubmitProps<ISignInFormData>) => {
    const { currentStep, step } = props;
    const { phone, code } = values;
    let token;
    try {
      const res = await requestCaptcha();
      token = res;
    } catch (e) {
      console.log('ERROR', e);
    }
    if (step.submit) {
      switch (currentStep) {
        case 2:
          step.submit(normalizePhone(phone), Number(code));
          break;

        default:
          step.submit(normalizePhone(phone), token).then(() => {
            formikActions.setSubmitting(false);
            formikActions.validateForm();
            formikActions.setTouched({});
          });
          break;
      }
    }
  };

  return (
    <Form
      initialValues={{ phone: '', confirmation: false, code: '', token: '' }}
      onSubmit={handleSubmit}
      validationSchema={step.validation}
      btnClassName="mb-24"
      loading={isLoading}
      enableReinitialize
      resetRequestErrors={resetRequestErrors}
      footer={
        <RepeatSend
          buttonActive={finalStep}
          repeatSend={repeatSend}
          sendCode={sendCode}
          executeRecaptcha={executeRecaptcha}
        />
      }
    >
      {currentStep === 1 && (
        <Input
          name="phone"
          label="Введите свой номер телефона"
          type="phone"
          readonly={finalStep}
          disabled={finalStep}
          error={errors && errors.phone}
          className="lg"
        />
      )}
      {step.component}
      <div id="captcha-container" />
    </Form>
  );
};
