import React from 'react';
import cn from 'classnames';

type Props = {
  className?: string;
  children: React.ReactNode;
};

export const UiCaption = ({ className, children }: Props) => (
  <p className={cn('caption textGray-40', className)}>{children}</p>
);
