import React, { Component } from 'react';
import { UiHeadline, UiSubtitle, UiCaption, UiButton } from '../../ui';
import { ISessionCheck } from '../../store/modules';

interface Props {
  confirm: () => void;
  data: ISessionCheck;
  error?: string;
  isPaymentError?: boolean;
  onUpdatePaymentMethod: () => void;
}

export class GetChargingConfirm extends Component<Props> {
  render() {
    const { confirm, data, error, isPaymentError, onUpdatePaymentMethod } = this.props;
    return (
      <>
        <UiHeadline>Убедитесь что вы у автомата №{data.station_short_number}</UiHeadline>
        <UiSubtitle>
          Адрес: {data.location_address}, {data.location_name}
        </UiSubtitle>
        {data.deposit ? (
          <UiSubtitle>{`Депозит — ${data.deposit.amount} ${data.deposit.currencySymbol}`}</UiSubtitle>
        ) : null}
        <UiSubtitle>{data.description1}</UiSubtitle>
        <UiCaption className="mt-8">{data.description2}</UiCaption>
        <UiButton className="btn_widthFull mt-24" onClick={isPaymentError ? onUpdatePaymentMethod : confirm}>
          {isPaymentError ? 'Методы оплаты' : 'Забрать зарядник'}
        </UiButton>
        {error && <div className="inputError textCenter">{error}</div>}
        <div className="textCenter mt-24">
          <a href="https://getenergy24.ru/content/public_oferta_rus.pdf" target="_blank">
            Условия аренды зарядника
          </a>
        </div>
      </>
    );
  }
}
