import React from 'react';
import { Link } from 'react-router-dom';

import { IconArrowLeft } from './Icons';
import { INavigation, INavigationItem } from '../store/modules';
import { observer, inject } from 'mobx-react';

interface Props {
  nav?: INavigation;
}

const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, current: INavigationItem) => {
  if (current.step > 1) {
    e.preventDefault();
    current.step === 2 ? current.reset() : current.goBack();
  }
};

export const UiHeaderBack = inject('nav')(
  observer(({ nav }: Props) => {
    return (
      <Link
        className="btn btn_bgTransparent bHeader__goBack"
        to={nav!.selected.to || '/'}
        onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => handleClick(e, nav!.selected)}
      >
        <IconArrowLeft className="icon btn__icon btn__leftIcon" />
        {nav!.selected.title || 'Назад'}
      </Link>
    );
  }),
);
