import React from 'react';
import { Link } from 'react-router-dom';

type Props = {
  to: string;
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  children: JSX.Element | string;
};

export const UiLink = ({ to, className, onClick, children }: Props) => {
  const test = /^http/g.test(to);
  return test ? (
    <a href={to} className={className} target="_blank">
      {children}
    </a>
  ) : (
    <Link className={className} to={to} onClick={onClick}>
      {children}
    </Link>
  );
};
