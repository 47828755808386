import { types, Instance, detach } from 'mobx-state-tree';
import { User, Map, Profile, Session, Navigation } from '.';
import { Charging } from './chargings.store';
import { getStorageChargingType } from '../helpers';

export const Store = types
  .model({
    user: User,
    profile: Profile,
    map: Map,
    session: Session,
    chargings: types.array(Charging),
    navigation: Navigation,
    router: types.optional(types.frozen(), {}),
  })
  .views(self => ({
    get defaultSelected() {
      return getStorageChargingType() || self.chargings[0].value;
    },
    get headerToken() {
      return {
        headers: {
          Authorization: `Bearer ${self.user.token}`,
        },
      };
    },
  }))
  .actions(self => ({
    resetToDefaultNav() {
      self.navigation.reset();
    },
    afterCreate() {
      if (self.user.isAuth) {
        self.profile.getProfile();
        self.session.getActiveSession();
      }
    },
  }));

export type IStore = Instance<typeof Store>;
