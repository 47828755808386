import { types, Instance, flow, getParent, applySnapshot, getSnapshot } from 'mobx-state-tree';
import { setStorageChargingType } from '../helpers';
import { auth } from '..';
import { IStore } from './root.store';

const RepeatSend = types
  .model({
    complete: types.optional(types.boolean, true),
    duration: types.maybe(types.number),
    time: types.maybe(types.string),
  })
  .actions(self => {
    let initialState = {};

    const afterCreate = () => {
      initialState = getSnapshot(self);
    };
    const reset = () => {
      applySnapshot(self, initialState);
    };

    return {
      afterCreate,
      reset,
    };
  });

export const User = types
  .model({
    token: types.optional(types.string, ''),
    chargingType: types.optional(types.maybeNull(types.string), '0'),
    loading: types.optional(types.boolean, false),
    errors: types.maybe(types.frozen()),
    repeatSend: types.optional(RepeatSend, {}),
  })
  .views(self => ({
    get root(): IStore {
      return getParent(self);
    },
    get isAuth(): boolean {
      return !!self.token;
    },
  }))
  .actions((self: any) => ({
    setChargingType(value: string) {
      setStorageChargingType(value);
      self.chargingType = value;
    },

    resetErrors() {
      self.errors = undefined;
    },

    sendCode: flow(function* sendCode(phone: number, token: string, cb?: () => void) {
      self.loading = true;
      try {
        yield auth.client.iamUsersSendCodePost(
          'ru',
          { phone, token },
          {
            headers: {
              'ge-platform-os': 'web',
            },
          },
        );
        self.root.navigation.selected.changeModel({
          step: 2,
        });
        self.repeatSend = {
          ...self.repeatSend,
          time: new Date().toISOString(),
          duration: 1,
          complete: false,
        };
        self.resetErrors();
        cb && cb();
      } catch (err) {
        const error = yield err.json();
        if (err.status === 400) {
          self.errors = {
            phone: 'некорректный формат номера телефона',
          };
        } else {
          const { message, ...errParams } = error;
          self.repeatSend = {
            ...self.repeatSend,
            ...errParams,
            complete: false,
          };
          self.resetErrors();
        }
        console.error('Failed request ', err);
      }
      self.loading = false;
    }),

    login: flow(function* login(phone: number, code: number, cb?: () => void) {
      self.loading = true;
      try {
        const dtype = Number(self.chargingType);
        const { token } = yield auth.client.iamUsersLoginPost({ phone, code });
        self.token = token;
        auth.setToken(token);
        self.root.profile.getProfile().then(() => {
          self.root.profile.cardInfoExist
            ? self.root.router.replace('/get-charging')
            : self.root.router.replace('/payment-method');
          self.root.session.getActiveSession();
        });
        self.resetErrors();
        cb && cb();
      } catch (err) {
        if (err.status === 400) {
          self.errors = {
            code: 'неверный или просроченный код',
          };
        } else {
          self.resetErrors();
        }
        console.error('Failed request ', err);
      }
      self.loading = false;
    }),

    logout() {
      self.token = '';
      auth.removeToken();
      self.root.profile.reset();
    },
  }));

export type IRepeatSend = Instance<typeof RepeatSend>;
export type IUser = Instance<typeof User>;
