import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import InputMask from 'react-input-mask';

import { Form } from '../../components';
import { UiContainer, UiHeadline, UiSpinner, UiInput, IconPaymentVisa, IconPaymentMastercard, UiText } from '../../ui';
import { IStore } from '../../store/modules';
import { RouteComponentProps } from 'react-router-dom';

interface Props {
  store: IStore;
}

interface State {
  isLoading: boolean;
  errors?: { [key: string]: string };
  cardholderName?: string;
  error?: string;
}

@inject('store')
@observer
export class PaymentMethodForm extends Component<Props & RouteComponentProps, State> {
  checkout: any;
  win3ds?: Window = undefined;

  state: State = { isLoading: false };

  componentDidMount() {
    const script = document.createElement('script');
    script.src = 'https://widget.cloudpayments.ru/bundles/checkout';
    script.onload = () =>
      setTimeout(
        () =>
          // @ts-ignore
          (this.checkout = new cp.Checkout(
            process.env.REACT_APP_CP_PUBLIC_ID,
            document.querySelector('#paymentMethodForm'),
          )),
      );
    document.body.appendChild(script);
  }

  handleCardholderNameChange = (e: any) => this.setState({ cardholderName: e.target.value });

  handleSubmit = () => {
    const result = this.checkout.createCryptogramPacket();

    if (!result.success) {
      this.setState({ errors: result.messages });
      return false;
    }

    this.setState({ errors: undefined, isLoading: true });
    this.handleAttach(result);
  };

  async handleAttach(result: { packet: 'string' }) {
    const { profile } = this.props.store!;

    this.setState({ error: undefined });

    try {
      const res = await profile.cardAttach(result.packet, this.state.cardholderName as string, 'cp_russia_tks');

      const data = await res.json();

      if (data.success) {
        this.handleSuccess();
      }

      if (typeof data === 'object' && data.AcsUrl) {
        const token = this.props.store!.user.token;

        this.win3ds = window.open() as Window;

        window.addEventListener('message', this.handle3dsSuccess);

        this.win3ds.document.body.innerHTML = `
        <form name="downloadForm" action="${data.AcsUrl}" method="POST">
          <input type="hidden" name="PaReq" value="${data.PaReq}">
          <input type="hidden" name="MD" value="${data.TransactionId}">
          <input type="hidden" name="TermUrl" value="${
            process.env.REACT_APP_API_URL
          }/mobile/card_3ds?appType=web&token=${token}">
        </form>
      `;
        // @ts-ignore
        this.win3ds.downloadForm.submit();
      }
    } catch (res) {
      const data = await res.json();
      if (data && data.error) this.setState({ isLoading: false, error: data.error });
    }
  }

  handle3dsSuccess = (e: MessageEvent) => {
    const data = JSON.parse(e.data || null);

    if (!data) return;

    if (data.success) this.handleSuccess();
    else this.setState({ isLoading: false, error: data.error });
    window.removeEventListener('message', this.handle3dsSuccess);
    this.win3ds && this.win3ds.close();
  };

  handleSuccess = () => {
    this.setState({ isLoading: false });
    this.props.store.profile.getProfile();
    this.props.history.push('/payment-method');
  };

  render() {
    const { profile } = this.props.store!;
    const { isLoading, errors = {}, error } = this.state;

    return (
      <UiContainer className="container_flex">
        <UiHeadline>Метод оплаты</UiHeadline>
        <div className="mb-24">
          {(profile.loading || isLoading) && <UiSpinner />}
          <Form
            initialValues={{}}
            onSubmit={this.handleSubmit}
            align="left"
            btnTitle="Привязать карту"
            statusTitle="Сохранено"
            id="paymentMethodForm"
            autoComplete="off"
          >
            <InputMask mask="9999 9999 9999 9999" maskChar={null} alwaysShowMask={false}>
              {(inputProps: any) => (
                <UiInput
                  label="Номер карты"
                  data-cp="cardNumber"
                  placeholder="XXXX XXXX XXXX XXXX"
                  error={errors.cardNumber}
                  errorMsg={errors.cardNumber}
                  {...inputProps}
                />
              )}
            </InputMask>
            <UiInput
              label="Имя и фамилия"
              data-cp="name"
              placeholder="IVAN IVANOV"
              error={errors.name}
              errorMsg={errors.name}
              value={this.state.cardholderName}
              onChange={this.handleCardholderNameChange}
            />
            <div className="row mb-8">
              <div className="col-6">
                <span className="label" style={{ whiteSpace: 'nowrap' }}>
                  Срок действия
                </span>
                <div className="row">
                  <div className="col-12 flex flex_horizontal" style={{ position: 'relative', alignItems: 'center' }}>
                    <InputMask mask="99" maskChar={null} alwaysShowMask={false}>
                      {(inputProps: any) => (
                        <UiInput
                          label=""
                          data-cp="expDateMonth"
                          placeholder="MM"
                          error={errors.expDateMonth}
                          errorMsg={errors.expDateMonth}
                          {...inputProps}
                          style={{
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                            borderRight: 'none',
                            textAlign: 'right',
                          }}
                        />
                      )}
                    </InputMask>
                    <InputMask mask="99" maskChar={null} alwaysShowMask={false}>
                      {(inputProps: any) => (
                        <UiInput
                          label=""
                          data-cp="expDateYear"
                          placeholder="YY"
                          error={errors.expDateYear}
                          errorMsg={errors.expDateYear}
                          {...inputProps}
                          style={{
                            borderTopLeftRadius: 0,
                            borderBottomLeftRadius: 0,
                            borderLeft: 'none',
                            textAlign: 'left',
                          }}
                        />
                      )}
                    </InputMask>
                  </div>
                </div>
              </div>
              <div className="col-6">
                <InputMask mask="999" maskChar={null} alwaysShowMask={false}>
                  {(inputProps: any) => (
                    <UiInput
                      type="password"
                      label="CVV"
                      data-cp="cvv"
                      error={errors.cvv}
                      errorMsg={errors.cvv}
                      {...inputProps}
                    />
                  )}
                </InputMask>
              </div>
            </div>
            {error && (
              <div className="row mb-8">
                <div className="col-12 textCenter">
                  <UiText color="red">{error}</UiText>
                </div>
              </div>
            )}
          </Form>
        </div>
      </UiContainer>
    );
  }
}
