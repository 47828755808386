import React from 'react';
import cn from 'classnames';
import { InfoCardList } from './InfoCardList';
import { InfoCardSimpleProps, InfoCardSimple } from './InfoCardSimple';

interface InjectedProps {
  [key: string]: any;
}

interface Props extends InfoCardSimpleProps {
  title: string;
  component?: React.ComponentType<any>;
  children?: React.ReactNode;
  theme?: 'bgGreen' | 'bgRed';
  show?: boolean;
  minimized?: boolean;
  injectedProps?: InjectedProps;
  onClick?: () => void;
  closeAll?: () => void;
  typeCard?: 'simple' | 'session' | 'helper';
  style?: any;
}

interface PropsMain extends Props {
  forwardedRef: React.Ref<HTMLDivElement>;
}

const defaultProps = {
  minimized: true,
  typeCard: 'simple',
};

export class InfoCard extends React.Component<PropsMain> {
  static readonly defaultProps = defaultProps;

  handlerSessionButton = (type?: string) => {
    const { injectedProps, closeAll } = this.props;
    if (injectedProps) {
      if (!injectedProps.closed) {
        closeAll && closeAll();
        injectedProps.onShowHelper(type);
      } else {
        injectedProps.onShowHelper();
      }
    }
  };

  renderByType = () => {
    const { typeCard, theme, minimized, content, btns, closeAll, component: Component, injectedProps } = this.props;
    switch (typeCard) {
      case 'session':
        return (
          Component && <Component {...injectedProps} onShowHelper={this.handlerSessionButton} minimized={minimized} />
        );
      case 'helper':
        return <InfoCardList {...injectedProps} />;

      default:
        return !minimized && <InfoCardSimple content={content} btns={btns} closeAll={closeAll} theme={theme} />;
    }
  };

  render() {
    const {
      forwardedRef,
      theme,
      show,
      children,
      typeCard,
      injectedProps,
      onClick,
      title,
      minimized,
      style,
    } = this.props;
    const headlineClassName =
      minimized && typeCard !== 'session' ? 'textBody-2 textCenter textBold mb-0' : 'headline textCenter mt-4';
    const minimizedClassMd = typeCard === 'session' && injectedProps && !injectedProps.closed ? minimized : false;
    return (
      <div ref={forwardedRef} className={cn('bMap__panel', { _minimized: minimizedClassMd })}>
        {show && (
          <div className={cn('panel panel_hasShadow', theme)} onClick={onClick} style={style}>
            <h2 className={headlineClassName}>{title}</h2>
            {this.renderByType()}
            {children}
          </div>
        )}
      </div>
    );
  }
}

export const InfoCardItem = React.forwardRef((props: Props, ref: React.Ref<HTMLDivElement>) => {
  const { children, ...otherProps } = props;
  return <InfoCard forwardedRef={ref} {...otherProps} />;
});
