import React from 'react';
import * as Yup from 'yup';

import { Form, Input, SubmitProps } from '../../components';
import { validationYup } from '../../utils';

type Props = {
  onSubmit: (phone: number) => void;
  resetRequestErrors?: () => void;
  error?: string;
  isPaymentError?: boolean;
  onUpdatePaymentMethod(): void;
};

type IGetChargingFormData = {
  code: string;
};

export class GetChargingForm extends React.Component<Props> {
  handleSubmit = ({ values }: SubmitProps<IGetChargingFormData>) => {
    const { onSubmit, isPaymentError, onUpdatePaymentMethod } = this.props;
    if (isPaymentError) onUpdatePaymentMethod();
    else onSubmit(Number(values.code));
  };

  render() {
    const { error, resetRequestErrors, isPaymentError } = this.props;

    return (
      <Form
        initialValues={{ code: '' }}
        onSubmit={this.handleSubmit}
        validationSchema={validationYup({
          code: Yup.string()
            .matches(/^[A-Za-z0-9-]*$/)
            .required(),
        })}
        resetRequestErrors={resetRequestErrors}
        btnTitle={isPaymentError ? 'Методы оплаты' : 'Готово'}
      >
        <Input name="code" type="number" label={''} error={error} />
      </Form>
    );
  }
}
