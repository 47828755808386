import React from 'react';
import { RouteComponentProps } from 'react-router';
import { observer, inject } from 'mobx-react';
import * as Yup from 'yup';

import { validationYup, phoneRegExp } from '../../utils';
import { FormStepProps } from '../../types';
import { IStoreMain } from '../../store';

import { UiContainer, UiHeadline, UiText } from '../../ui';
import { SignInForm } from './SignInForm';
import { SignInStepOne, SignInStepTwo } from './Steps';

interface Props extends RouteComponentProps<any> {}

@inject('store')
@observer
export class SignIn extends React.Component<Props & IStoreMain> {
  private steps: FormStepProps[] = [
    {
      component: <SignInStepOne />,
      validation: validationYup({
        phone: Yup.string()
          .matches(phoneRegExp)
          .required(),
        token: Yup.string(),
        confirmation: Yup.bool()
          .test('confirmation', 'Необходимо принять условия', value => value === true)
          .required(),
      }),
      submit: this.props.store.user.sendCode,
    },
    {
      component: <SignInStepTwo />,
      validation: validationYup({
        code: Yup.string()
          .matches(/\d{4}$/i)
          .length(4)
          .required(),
      }),
      submit: this.props.store.user.login,
    },
  ];

  componentDidMount() {
    const { navigation } = this.props.store!;
    navigation.changeSelected('auth');
  }

  componentWillUnmount() {
    this.props.store!.user.resetErrors();
    this.props.store!.resetToDefaultNav();
  }

  render() {
    const { user, navigation } = this.props.store;
    const currentStep = navigation.selected.step;

    return (
      <UiContainer>
        <UiHeadline>
          Войдите, чтобы воспользоваться
          <br />
          <UiText color="green">GET ENERGY</UiText>
        </UiHeadline>
        <SignInForm
          isLoading={user.loading}
          currentStep={currentStep}
          step={this.steps[currentStep - 1]}
          sendCode={user.sendCode}
          repeatSend={user.repeatSend}
          errors={user.errors}
          resetRequestErrors={user.resetErrors}
        />
      </UiContainer>
    );
  }
}
