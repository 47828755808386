import React from 'react';
import { Link } from 'react-router-dom';

export const UiMenuFooter = () => (
  <div className="mt-auto">
    <div className="textBold halfTransparent">Поддержка</div>
    <a className="bMainMenu__phoneNumber" href="tel:+7 (495) 642-27-56">
      +7 (495) 642-27-56
    </a>
    ,{' '}
    <a className="bMainMenu__phoneNumber" href="tel:8 (800) 550-16-32">
      8 (800) 550-16-32
    </a>
  </div>
);
