import React from 'react';

type Props = {
  show?: boolean;
};

export const UiSpinner = ({ show = false }: Props) => (
  <div className="bPopup__overlay _spinner">
    <div className="spinner">
      <div />
      <div />
      <div />
    </div>
  </div>
);
