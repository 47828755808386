import React from 'react';

import { UiHeader, UiHeaderLogo, UiMenu, UiMenuButton, UiMenuFooter, UiMenuLinks, UiButton } from '../../ui';
import { auth } from '../../store';
import { IUser } from '../../store/modules';

const menuLinks = [
  { label: 'Взять зарядник', to: '/get-charging', isDivided: true, private: true },
  // { label: 'Промо-код', to: '/', isDivided: false },
  // { label: 'Вопросы и ответы', to: '/', isDivided: false },
  { label: 'Контакты', to: '/contacts', isDivided: false },
  { label: 'Правила аренды', to: 'https://getenergy24.ru/content/public_oferta_rus.pdf', isDivided: true },
];
const privateLinks = [
  { label: 'Профиль', to: '/profile', isDivided: false },
  { label: 'Методы оплаты', to: '/payment-method', isDivided: false },
  { label: 'Выйти', isDivided: false, logout: true },
];

interface Props {
  user: IUser;
  sessionActive?: boolean;
}

type State = typeof initialState;

const initialState = Object.freeze({
  isOpen: false,
});

export class Header extends React.Component<Props, State> {
  readonly state = initialState;

  handleClick = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  render() {
    const { isOpen } = this.state;
    const { user, sessionActive } = this.props;
    const secure = sessionActive || !user.isAuth;
    return (
      <UiHeader>
        <UiHeaderLogo />
        <UiMenuButton isOpen={isOpen} onClick={this.handleClick} />
        <UiMenu isOpen={isOpen}>
          <UiHeaderLogo inMenu />
          <UiMenuLinks secure={secure} links={menuLinks} />

          {user.isAuth ? (
            <UiMenuLinks secure links={privateLinks} handleLogout={user.logout} />
          ) : (
            <UiButton className="mt-20" link="/sign-in">
              Войти
            </UiButton>
          )}

          <UiMenuFooter />
          <UiMenuButton isOpen onClick={this.handleClick} />
        </UiMenu>
      </UiHeader>
    );
  }
}
