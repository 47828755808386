import React from 'react';

type Props = typeof defaultProps & {
  value: any;
  duration?: number;
  fn: (...args: any[]) => any;
  cb?: () => void;
};

interface State {
  timer?: any;
}

const defaultProps = {
  interval: 1000,
};

export class Timer extends React.Component<Props, State> {
  static readonly defaultProps = defaultProps;
  state = {
    timer: this.props.fn(this.props.value, this.props.duration),
  };

  timerID: any = null;

  componentDidMount() {
    this.timerID = setInterval(() => this.tick(), this.props.interval);
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  tick() {
    const { fn, value, duration, cb } = this.props;
    const timer = fn(value, duration);
    if (timer <= 0) {
      clearInterval(this.timerID);
      cb && cb();
    } else {
      this.setState({ timer });
    }
  }

  render() {
    return <span>{this.state.timer}</span>;
  }
}
